import React, { Fragment } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import dot from "../img/dot.png";
import menu from "../img/menu.png";

function Navbar() {
	const menuClick = () => {
		if (window.innerWidth <= 500) {
			const elements = document.getElementsByClassName("links");
			_.values(elements).forEach((key) => {
				if (key.style.display != "none" && key.style.display != "") {
					document.getElementById("fadeCover").style.display = "none";
					key.style.display = "none";
					document.getElementById("menu").style.width = "80px";
					document.getElementById("menu").style.transform = "rotate(0deg)";
					document.getElementById("menu").style.transform = "translateY(-20px)";
					document.body.style.overflowY = "";
				} else {
					document.getElementById("fadeCover").style.display = "block";
					key.style.display = "flex";
					document.getElementById("menu").style.width = "120px";
					document.getElementById("menu").style.transform = "rotate(90deg)";
					document.body.style.overflowY = "hidden";
				}
			});
		}
	};
	return (
		<Fragment>
			<div id="navContainer">
				{/* <button className="latest">Issue 3 is out! Read here</button> */}
				<div className="navbar">
					<img onClick={() => menuClick()} id="menu" src={menu} alt="" />
					<div id="fadeCover"></div>

					<Link onClick={() => menuClick()} className="links" to="/">
						home
						{/* mommy loves you*/}
					</Link>
					<img src={dot} alt="" height="10px" />
					<Link onClick={() => menuClick()} className="links" to="/praxis">
						praxis
						{/* ideology */}
					</Link>
					<img src={dot} alt="" height="10px" />
					<Link onClick={() => menuClick()} className="links" to="/issues">
						issues
						{/* you have them */}
						{/* we still care */}
					</Link>
					<img src={dot} alt="" height="10px" />
					<Link onClick={() => menuClick()} className="links" to="/gallery">
						gallery
						{/* consume and feel good */}
					</Link>
					<img src={dot} alt="" height="10px" />
					<Link onClick={() => menuClick()} className="links" to="/submit">
						submit
						{/* you know you want to */}
					</Link>
				</div>
			</div>
		</Fragment>
	);
}

export default Navbar;
