import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue3/tendies/1.jpg";
import i2 from "../../../../img/issues/issue3/tendies/2.jpg";
import i3 from "../../../../img/issues/issue3/tendies/3.jpg";
import i4 from "../../../../img/issues/issue3/tendies/4.jpg";
import i5 from "../../../../img/issues/issue3/tendies/5.jpg";
import i6 from "../../../../img/issues/issue3/tendies/6.jpg";

function Tendies_3() {
	return (
		<Fragment>
			<h1 className="articleTitle">Chicken Tenders for the Crypto NEET</h1>
			<div id="h">Hardwood_</div>
			<br />
			<div id="h">October 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div>
					<span className="leadLetter">T</span>ink, clonk - spent cans of Red
					Bull and Gatorade go rolling. Sports drinks for a proud eSportsman. A
					luminous cone spills through the door, timidly ventures into a dark
					monitor-lit room. Heaps of stained clothes lay about, as crinkled as
					the lines on her face. The faint reek of garlic and sweat adds
					another.
				</div>
				<div>At least there's a path back to the door.</div>
				<div>
					Mom ventures in, taking care not to disturb the harem of exquisitely
					crafted anime wives.
				</div>
				<div>
					She sets the plate of fried chicken down on the bedside table. "I'll
					leave your breakfast here, sweetie." The child - man? - in the chair
					swivels about, annoyed.
				</div>
				<div>
					He scrutinizes the food, then screeches: "What the fuck mom?! Where
					the tendie sauce?!"
				</div>
				<div>
					Now, what is a tendie? You might ask. A tendie is a chicken tender,
					oft referred to as such by frequenters of the 4chan. To some they are
					known as chicken fingers, a popular finger food in their own right.
					Easy to eat, and tasty, tendies are deep-fried breaded strips of
					chicken, often served with sauce as a treat for children.
				</div>
				<img src={i1} alt="" />
				<div>
					The simplicity of this foodstuff belies the complexity of the
					tendiecrafting process. To produce each tendie, great sacrifices must
					be made. To make tendies, first hatch your chicken, then feed it for
					seven weeks. Collect some unfertilized eggs from its mother while
					you're at it. Then catch your chicken, slaughter it, and process the
					meat. Cut up the meat, or just buy your chicken in strips and skip the
					previous steps.
				</div>
				<div>
					Take a single strip of chicken. Submerge it in beaten eggs, then
					dredge it in flour, making sure to fully coat the meat's every crack
					and flap. Get it all up in there; raw chicken has many crevices,
					you'll find.
				</div>
				<div>
					Wash your hands. This is very important as the next step requires that
					you have flour-free hands. Wash them thoroughly and dry them off.
				</div>
				<div>
					Egg the floured chicken, trying to touch it as little as possible in
					the process. You can use tongs for this step, but they'll have to be
					cleaned and dried each time.
				</div>
				<div>
					Dip the chicken into the breading, rolling it about until well-coated.
					Then set it aside on a plate.
				</div>
				<div>
					Wash your hands again, and repeat the process for each tendie you're
					making. After all, when one speaks of tendies, he refers to an
					abundance of them. Only a poor plebeian would be so foolish as to
					settle for only a single tendie.
				</div>
				<img src={i2} alt="" />
				<div>
					After the tendies have been prepared, chill them for a few hours in
					the fridge or one hour in the freezer. Chilling them helps the
					breading to not fall off during the frying process.
				</div>
				<div>
					You may want to use this time to prepare some sides, such as fries or
					hash browns.
				</div>
				<div>
					​ After the tendies have been sufficiently chilled, deep-fry them
					until golden-brown, turning as needed.
				</div>
				<div>
					Upon extraction, pat them down with paper towels and rest them on some
					more fresh paper towels until the excess frying oil has been drained
					to your satisfaction.
				</div>
				<div>Promptly dispose of all the excess wasted ingredients.</div>
				<img src={i3} alt="" />
				<div>
					Congratulations! Your tendies are ready to eat. But not so fast - what
					are tendies without sauce? What kind of uncivilized African savage
					would eat such a thing? The very existence of tendies implies sauce.
					They are inseparable, as cart and horse, as peanut butter and jam, as
					morning sun and spring wind!
				</div>
				<div>
					Personally, <span style={{ background: "salmon" }}>as a gay man</span>
					, I enjoy a special mix of peanut sauce, raspberry jam, and dried
					chili peppers. Blend the ingredients until smooth, or in the absence
					of a blender, crush and mix them the old-fashioned way, with mortar
					and pestle. It's quite tasty. Red-blooded heterosexual American males
					may prefer ketchup, of course.
				</div>
				<img src={i4} alt="" />
				<div>
					And now you're ready to eat. Savor each piece, or wolf them down as if
					the singular noun never existed - it's all up to you. The tendies are
					all yours now. They can even be used as legal tender. Not even the
					Feds could take them away from you.
				</div>
				<img src={i5} alt="" />
				<div>
					When you're done, don't forget to clean the kitchen and do the dishes.
					Raw chicken is super gross and carries tons of germs that could make
					you very sick, so you'll have to be thorough. Make sure to scrub and
					disinfect every surface and cooking utensil that you've handled. You
					wouldn't want to catch salmonella. Everyone loves a good
					feces-flinging joke, but the joke will be on you if it's not solid
					enough to throw...
				</div>
				<div>
					Enjoy your tendies! If someone else has crafted this decadent
					first-world first-class meal for you, give your compliments to the
					chef. You may even kiss her.as;fgh;SKJdhf;ksdjfh;aKSHD'ailsdhj
				</div>
				<div style={{ textAlign: "center" }}>Happy Mother's Day. $TEND</div>
				<img src={i6} alt="" />
			</div>
		</Fragment>
	);
}

export default Tendies_3;
