import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue3/sushi/1.jpg";
import i2 from "../../../../img/issues/issue3/sushi/2.jpg";
import i3 from "../../../../img/issues/issue3/sushi/3.jpg";
import i4 from "../../../../img/issues/issue3/sushi/4.jpg";
import i5 from "../../../../img/issues/issue3/sushi/5.jpg";
import i6 from "../../../../img/issues/issue3/sushi/6.jpg";
import i7 from "../../../../img/issues/issue3/sushi/7.jpg";
import i8 from "../../../../img/issues/issue3/sushi/8.jpg";
import i9 from "../../../../img/issues/issue3/sushi/9.jpg";
import i10 from "../../../../img/issues/issue3/sushi/10.jpg";

function Sushi_3() {
	return (
		<Fragment>
			<h1 className="articleTitle">The Great Sushi Wars</h1>

			<div id="h" style={{ wordWrap: "break-word" }}>
				TheRealMasterChef696969696969696969696969696969
			</div>
			<br />
			<div id="h">October 2020</div>
			<div className="articleLine"></div>

			<div className="articleTextContainer">
				<div id="specialText1">All hail our Supreme Leader of Serum:</div>
				<div id="specialText1" style={{ fontSize: "1.8rem" }}>
					SBF
				</div>
				<img src={i1} alt="" />
				<img src={i2} alt="" />
				<img src={i3} alt="" />
				<div>
					<span className="leadLetter">E</span>arlier in September, as the
					theatrics of the Sushi Swap PsyOps and vampire attack campaign were in
					full force, followed by Chef Nomi's dump and exit and the voter
					collusion scandal surrounding the multisig key holders, it was clear
					that Uniswap's moat of liquidity had been breeched and SBF and his
					Serum Slave farms had won the vampire attack battle for Uniswap's
					liquidity. In Sam's world, he is both the house and the player in his
					own casino, and he is playing to win. The only thing that's certain in
					Sam's casinos is that Sam will be making money- Sam doesn't farm
					shitcoins, Sam farms YOU. All hail the real king of making money
					(sorry Justin).
				</div>
				<img src={i4} alt="" />
				<img src={i5} alt="" />
				<div
					style={{
						color: "red",
						fontSize: "1.8rem",
						fontStyle: "italic",
						textAlign: "center",
						fontWeight: 5000,
						// zIndex: 5,
						padding: "15px 0 15px 0",
					}}
				>
					Degen Stimulus
				</div>
				<img src={i6} alt="" />
				<div>
					Hayden and Uniswap fought back in full force by releasing the $UNI
					token and airdropping 400 UNI to every Uniswap user, instituting the
					real stimulus package,{" "}
					<span
						style={{
							background: "red",
							fontStyle: "italic",
						}}
					>
						Degen UBI
					</span>{" "}
					- a new PlayStation for every degen Uniswap trader, putting even Trump
					bucks to shame. Skip the middleman and inject that stimulus package
					straight back into DeFi, let the kids try their luck in the
					intellectual casinos. Early Uniswap LPs, especially V1 LPs, were
					generously rewarded, including the top recipient of the airdrop,
					Anal.eth, here to remind Sushi Swap heretics that he will pound ass in
					the name of Uniswap.
				</div>
				<img src={i7} alt="" />

				<img src={i8} alt="" />
				<div>
					But Uniswap still doesn't have a moat beyond its liquidity and the
					feel good vibes surrounding the $UNI airdrop. As farming begins to
					move to other chains such as Solana, Tron, Binance chain and Polkadot,
					how will Uniswap and even Ethereum keep their position as the DeFi and
					farming center of crypto? How will crypto retain its open source and
					grassroots ethos in the age of commercial, centralized chains like
					Tron, Solana, and Binance? Is the ultimate fate of every humble farmer
					a life on the Serum Slave Farms or toiling away with the plebs (no
					fees!) in Justin's Pearl and Salmon farm labour camps?
				</div>
				<div
					style={{
						color: "red",
						fontSize: "1.8rem",
						fontStyle: "italic",
						textAlign: "center",
						fontWeight: "bolder",
					}}
				>
					💋Kiss and Make Up💋
				</div>
				<img src={i9} alt="" />
				<div>
					While many Ethereans have a negative opinion about Dr. Gavin Wood and
					their personal feelings towards him limit their ability to asses
					Polkadot seriously, many Polkaheads see the future of Ethereum as
					being a glorified parachain in the Polkadot ecosystem. It's fair to
					say there is no love lost between the two networks. But which other
					network echoes the open source, grassroots ethos of Ethereum than
					Polkadot?
				</div>
				<div>
					No one designs post modern hyper capitalist casinos like Gavin;
					Polkadot is an immaculately elegant cryptoverse. Above the actual tech
					of the relay chain lies an intricate, multi layered game consisting of
					kingdom makers, kingdom subjects and ecosystem global participants - a
					multiverse of casino kingdoms. Ethereum has become a global casino for
					degens but Ethereum holders don't own a piece of the house. Polkadot
					allows every DOT holder to own a piece of the house. Decentralize
					SBFism!
				</div>
				<div>
					As we see farming starting to move into the Polkadot ecosystem and new
					EOS-esque networks like Solana and Tron gain prominence, it's time for
					Gavin's brilliantly baroque game design to truly shine. The world
					computer narrative is dead, long live the world casino narrative!
				</div>
				<div
					style={{
						color: "red",
						fontSize: "1.8rem",
						fontStyle: "italic",
						textAlign: "center",
						fontWeight: "bolder",
					}}
				>
					Digital Maki Rolls and the End of History🥂🍣
				</div>
				<div>
					Is there no greater totem for late stage capitalistic mercenary
					degeneracy than the digital maki roll? Are the SushiSwap shenanigans
					the existential hill that DeFi is willing to die on? I think that
					SushiSwap just exposed what was already evident to many — liquidity is
					king and if the liquidity providers have a choice, they'll go to where
					the best APY's are.
				</div>
				<img src={i10} alt="" />
				<div>
					There are no moats with open source technology and crowd sourced open
					liquidity. Someone can clone Sushi Swap, create their own reward
					tokens and get 8-9 figures TVL in a matter of days. After the
					mega-forking of YFI and now SushiSwap, and tools like Degenerator that
					will allow you to make your own out-of-the-box copy/paste of the
					favorite ponzis of the day, the negative connotation and gaucherie
					surrounding hijacking liquidity and forking has ended. Now, if you
					have a problem with the direction of a project, the premine and/or
					distribution, just fork it and go your own way. In some way this is
					the accelerationist narrative for open source technology and open
					economy- in an open terrain, everything can be forked, all liquidity
					can be co-opted and is free to flow, there are no moats, only market
					participants. This is the open economy of which we all dreamed. Having
					big players like Sam dominating the ecosystems they participate in is
					just a reality we have to cope with.
				</div>
				<div>
					In all reality, there is no such thing as a fair launch either; early
					participants either have more information with which to act upon or
					early opportunities to invest at low valuations. As a casino designer,
					who do you want to give an edge to? A curated list of early VCs,
					investors and early adopters (Uniswap), SBF (Sushiswap), or other
					fellow owners of the casino (Polkadot)?
				</div>
			</div>
		</Fragment>
	);
}

export default Sushi_3;
