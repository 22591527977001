import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue2/memes/1.webp";
import i2 from "../../../../img/issues/issue2/memes/2.webp";
import i3 from "../../../../img/issues/issue2/memes/3.webp";
import i4 from "../../../../img/issues/issue2/memes/4.webp";
import i5 from "../../../../img/issues/issue2/memes/5.webp";
import i6 from "../../../../img/issues/issue2/memes/6.webp";
import i7 from "../../../../img/issues/issue2/memes/7.webp";
import i8 from "../../../../img/issues/issue2/memes/8.webp";
import i9 from "../../../../img/issues/issue2/memes/9.webp";
import i10 from "../../../../img/issues/issue2/memes/10.webp";

function Memes_2() {
	return (
		<Fragment>
			<h1 className="articleTitle">Meme Blocks</h1>
			<div id="h">{null}</div>
			<br />
			<div id="h">August 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<img src={i1} alt="" />
				<div style={{ textAlign: "center", color: "#B26CE3" }}>
					Genius trader Sam Bankman-Fried single handedly resurrects the IEO by
					using his CEX to IEO his DeFi token.
				</div>
				<img src={i2} alt="" />
				<div style={{ textAlign: "center", color: "#B26CE3" }}>
					I'm not ~gay but 1 galaxy = 1 galaxy
				</div>
				<img src={i3} alt="" />
				<div style={{ textAlign: "center", color: "#E3D48C" }}>
					Does owning an urbit galaxy get you a lifetime subscription to Curtis'
					substack?
				</div>
				<div style={{ textAlign: "center", color: "#E3D48C" }}>
					Who wants to generate stars when having your galaxy generate Curtis
					content is so much more satisfying?
				</div>
				<div style={{ textAlign: "center", color: "red" }}>
					The endarkenment scholar is back.
				</div>
				<div style={{ textAlign: "center", color: "#E3D48C" }}>
					Reading his Bitzion article, one has to wonder if he has ever
					considered Polkadot in his quest for the theatre of liquid democracy
					within an aristocracy.
				</div>
				<img src={i4} alt="" />
				<img src={i5} alt="" />
				<img src={i6} alt="" />
				<img src={i7} alt="" />
				<div style={{ textAlign: "center", color: "#E3D48C" }}>
					<a className="textLink" href="polkaswap.io">
						PSWAP
					</a>{" "}
					sets a new bar for FOMO in the DeFi space.
				</div>
				<img src={i8} alt="" />
				<img src={i9} alt="" />
				<img src={i10} alt="" />
			</div>
		</Fragment>
	);
}

export default Memes_2;
