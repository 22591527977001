import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue1/degen/1.webp";
import i2 from "../../../../img/issues/issue1/degen/2.webp";
import i3 from "../../../../img/issues/issue1/degen/3.webp";
import i4 from "../../../../img/issues/issue1/degen/4.webp";
import i5 from "../../../../img/issues/issue1/degen/5.webp";
import i6 from "../../../../img/issues/issue1/degen/6.webp";

function Degen_1() {
	return (
		<Fragment>
			<h1 className="articleTitle">TFW You're a Based Degen Trader</h1>
			<div id="h">Anonymous</div>
			<br />
			<div id="h">July 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<img src={i1} alt="" />
				<img src={i2} alt="" />
				<div style={{ textAlign: "center", color: "yellow" }}>
					USDT is the reserve currency of DeFi, not ETH. The network effects go
					where the USDT flows.
				</div>
				<br />
				<br />
				<br />
				<div style={{ textAlign: "center", color: "magenta" }}>
					Coin Market Cap is dead, long live Coin Gecko!
				</div>
				<img src={i3} alt="" />
				<div style={{ textAlign: "center", color: "magenta" }}>
					Coin Gecko is the place to get the most accurate and up-to-date data
					on your shitcoins. CMC died when they sold out to Binance and added a
					trollbox for data collection.
				</div>
				<br />
				<br />
				<br />
				<div
					style={{
						textAlign: "center",
						color: "purple",
						paddingBottom: "25px",
						// zIndex: "5",
					}}
				>
					The DeFi space is a global casino for autistic traders and degen
					crypto whales.
				</div>
				<img src={i4} alt="" />
				<br />
				<br />
				<br />
				<div style={{ textAlign: "center", color: "magenta" }}>
					Accelerating the demise of ETH via DeFi
				</div>
				<div style={{ fontSize: ".9rem", textAlign: "center", color: "red" }}>
					"The candle that burns twice as bright burns half as long" - Lao Tzu
				</div>
				<br />
				<br />
				<br />
				<img style={{ maxHeight: "70vh" }} src={i5} alt="" />
				<div>
					<span className="leadLetter">D</span>eFi is Landian hypercapital in
					praxis in the sandbox of Ethereum.
				</div>
				<div>Yield farming is the accelerationist narrative for Ethereum.</div>
				<div>It's demise is hastened with DeFi adoption.</div>
				<div>
					EIP 1559 languishes and there is no plan for how scaling DeFi on ETH
					is possible without breaking composability.
				</div>
				<div>
					Meanwhile, childking V tries to grasp for control back of the
					narrative but is failing miserably, telling the peasants they can't
					farm their land, creating a fissure in Ethereum between its creators
					and maintainers and its users and adopters.{" "}
					<a className="textLink" href="https://twitter.com/defikaren">
						DeFi Karen
					</a>{" "}
					is calling his manager.
				</div>
				<div>
					<a
						className="textLink"
						href="https://twitter.com/_prestwich/status/1274469966763315200?s=20"
					>
						"You should give up money and things you value, to work on things I
						value.
					</a>
				</div>
				<div>Is yield farming a psy-op to get us to spend $50/day on gas?</div>
				<div>
					A slow war of attrition against ETH holders infected with the farming
					meme?
				</div>
				<img src={i6} alt="" />
			</div>
		</Fragment>
	);
}

export default Degen_1;
