import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import iData from "../../../store/IssueData";

function Issue_1() {
	const routes = {
		0: "bondage",
		1: "szabo",
		2: "degen",
		3: "polkaheads",
		4: "femdom",
		5: "yield",
	};
	return (
		<Fragment>
			<h1>July 2020 Issue</h1>
			<div className="articleContainer">
				{iData[0].articles.map((article, i) => (
					<Link
						id={`1_` + routes[i]}
						className="articleBox"
						key={i}
						to={`/issues/1/` + routes[i]}
					>
						<div className="listTitle">{article[0]}</div>
						{article[1]}
					</Link>
				))}
			</div>{" "}
		</Fragment>
	);
}

export default Issue_1;
