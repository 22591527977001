import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue4/eugenic/1.png"
import i2 from "../../../../img/issues/issue4/eugenic/2.png"
import i3 from "../../../../img/issues/issue4/eugenic/3.png"
import i4 from "../../../../img/issues/issue4/eugenic/4.png"
import i5 from "../../../../img/issues/issue4/eugenic/5.png"
import i6 from "../../../../img/issues/issue4/eugenic/6.png"

function Eugenic_4() {
	return (
		<Fragment>
			<h1 className="articleTitle">A Eugenic Cetacean Manifesto</h1>
			<div id="h">AtlanteanDreamz</div>
			<br />
			<div id="h">November 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
                <div><span className="leadLetter">T</span>here’s a battle raging in the West, dear reader. It’s a passionate, existential battle - the kind that only arises once a century or less. It’s a battle over the deepest question of human identity, and the winning side will permanently reshape civilization in its image.
</div>
        <div>The battle is over whether Man should devolve into a slime creature. 
</div>
        <img src={i1} alt=""/>
        <div style={{textAlign:"center"}}>If this arouses you, then you work for Big Slime
</div>
            <br/>
            <div>The opposition (let’s call it Big Slime, or BS) is smart and powerful. Big Slime owns the media, the university system, the government, and every other relevant institution in the West. Schooled in the arts of Talmudic rhetoric, BS denies the entire premise of the battle itself, and viciously destroys the life of anyone who publicly brings up the notion that Man might be undergoing a dysgenic slimification process. In fact, BS is so good at memeing, it’s been able to mindfuck most of its purported foes into believing that slimification isn’t happening at all!
</div>
            <div>Remember when you taught your dog how to do calculus? Me neither. Know what your problem is? You didn’t play Beethoven for little Fido when he was in the womb. You didn’t send him to puppy pre-school, didn’t speak enough words to him when he was young, and you didn’t give him the right environment to thrive in. As any right-thinking Millennial knows, intelligence is completely environmental. Do you believe otherwise? Then you’re Literally Hitler. Because if intelligence (and other good traits) are heritable, and there are differential breeding patterns in the wrong direction, well… you’ve seen Idiocracy, right?
</div>
<img src={i2} alt=""/>
        <div style={{textAlign:"center"}}>This could have been your dog if you weren’t a Nazi</div>
        <br/>


            <div>Post-Malthusian industrial civilization, the welfare state, the Pill, and Big Slime have destroyed the reproductive incentive system that, until the late 19th century, served as a virtuous circle, guiding Man to unparalleled heights of scientific achievement and biological quality. Wealth, achievement and education (all proxies for biological quality) used to correlate strongly with family size. Today, the opposite is the case. The average less-than-high school-educated person in America today has 2.4 kids. The average person with a graduate degree? 1.71 children. 
</div>
            <img src={i3} alt=""/>
                    <div style={{textAlign:"center"}}>These numbers aren’t BS
</div>
        <br/>

            <div>Francis Galton, a Victorian scientist and polymath, invented the field of psychometrics - he chose random samples (Chad and slime alike) of the English population and tested their ‘simple reaction times’ (SRTs), that is, how quickly they pressed a button in response to a light appearing. This test has been repeated numerous times in the 100+ years since Galton’s first experiment, and the trend has been un-good. Did I mention SRT correlates strongly with brain function and IQ? The Flynn effect was real, and it masked this problem, but it’s over now. The best software on earth (high-quality education) can’t compensate for increasingly slimy hardware.
</div>
<img src={i4} alt=""/>
                    <div style={{textAlign:"center"}}>It might take you a while to realize it, but we’re on the right side of the graph</div>
        <br/>
            <div>It wasn’t always like this. There are ancient tales, first revealed to us by the pre-eminent philosopher Plato, of a fallen city. A city filled with citizens so big-brained, they would have bought BTC in 2011 if they’d been around to do it. A city of high technology, populated by beings who were half-god and half-men. A city known as Atlantis. It was in Atlantis that Man first rose from barbarism, from slime, into an advanced, civilized state. According to Plato, Atlantis represented the peak of prosperity and scientific knowledge in human civilization. And then, one sudden, terrible day, it was struck by a massive earthquake and disappeared into the ocean, its knowledge, secrets and biological wealth lost forever. Or were they?
</div>
<img src={i5} alt=""/>
                    <div style={{textAlign:"center"}}>The only real estate more expensive than San Francisco’s
</div>
        <br/>
        <div>Maybe not all was lost. Maybe, like with the Silk Road, there are keyholders from long ago who can bring ancient wealth and knowledge into the present day and dump it on all of us. The proprietors of Shitcoin Praxis and I have deeply investigated the issue, and we’ve made a shocking discovery: The Atlantean legacy has been carried forward through millennia by a species near and dear to mankind: Dolphins. Our cetacean brothers and sisters are the archivists and storeholders of ancient, hidden knowledge. Some cetacean scholars posit that they’ve tried to communicate this knowledge to us over thousands of years, but we simply haven’t listened. Worse, we’ve turned these underwater sages into vapid aquarium pets, leaping out of the water and doing silly tricks for the amusement of Midwestern crowds. 
</div>
<div>That ends today.
</div>
<div>Cetaceans will help save Man from Big Slime’s evil machinations, but only if we open our hearts, minds and wallets to them. I hereby announce the creation of the <span style={{fontWeight:"bold"}}>Eugenics DAO</span>. Eugenics DAO will build a breeding laboratory and use the latest gene editing and embryonic selection techniques (in conjunction with cetacean/Atlantean knowledge) to breed a new, superior generation of humans. The United States government, working with Big Slime, will undoubtedly oppose this plan, so Eugenics DAO will need to lease or purchase an island, preferably somewhere in the Atlantic. It’ll be a place where Man and dolphin can co-exist and transcend their flesh-bags, engineering themselves into gods. 
</div>
<div>We’ll call it Atlanteth.
</div>
<img src={i6} alt=""/>
               <div style={{textAlign:"center"}}>Cetaceans invented ETH over 9000 years before Vitalik was born

</div>
			</div>
		</Fragment>
	);
}

export default Eugenic_4;
