import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue2/waifus/1.jpg";
import i2 from "../../../../img/issues/issue2/waifus/2.jpg";
import i3 from "../../../../img/issues/issue2/waifus/3.jpg";

function Waifus_2() {
	return (
		<Fragment>
			<h1 className="articleTitle">YFI's Forking Waifus</h1>
			<div id="h">WEEB</div>
			<br />
			<div id="h">August 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div style={{ textAlign: "center", fontSize: "1.8rem" }}>
					"Simping the same waifu is how you get world peace"
				</div>
				<div>
					<span className="leadLetter">I</span>f you've been paying attention to
					the latest DeFi craze, you may have heard of YFI and its short-lived
					3000% APY yield farming returns.{" "}
				</div>
				<div>
					However, yfi's creator -{" "}
					<span
						style={{
							fontFamily: "inheret",
							background: "#19731C",
							fontStyle: "italic",
							textTransform: "none",
						}}
					>
						Andre "0 value token" Cronje
					</span>{" "}
					insists that YFI does not have any value and that you should not buy
					it.🙈
				</div>
				<div>
					Unlike many other 2017 SCAM tokens with their dirty ICOs and premines,
					YFI was initially minted and distributed only to people who staked
					their yCRV - an interest generating stablecoin aggregation token.
				</div>
				<div>
					This meant that there was no private sale😤, no VCs😤, and no media
					spotlight😤.{" "}
				</div>
				<div>
					However, thanks to{" "}
					<span
						style={{
							fontFamily: "inheret",
							background: "#167C75",
							textTransform: "none",
							fontStyle: "italic",
						}}
					>
						@ChainLinkGod
					</span>
					, crypto twitter quickly got a hold of this news and the rumour of
					yearn coin started to spread.
				</div>
				<div>
					Of course the DeFi community already knew how much of a megamind{" "}
					<span
						style={{
							fontFamily: "inheret",
							background: "#19731C",
							fontStyle: "italic",
							textTransform: "none",
						}}
					>
						Andre "I test in prod" Cronje
					</span>{" "}
					is, which resulted resulted in $50 million TVL for{" "}
					<a className="textLink" href="yearn.finance">
						Yearn.Finance
					</a>{" "}
					within only a couple of hours. 😵📈
				</div>
				<img src={i1} alt="" />
				<div>
					After realizing how little YFI he had,{" "}
					<span
						style={{
							fontFamily: "inheret",
							background: "#19731C",
							fontStyle: "italic",
							textTransform: "none",
						}}
					>
						Andre "Poolmaster" Cronje
					</span>{" "}
					set up a 98/2 DAI/YFI pool that is incentivized to make people dump
					YFI which would give him the opportunity to buy more of it. A 98/2
					imbalance permits a forgiving impermanent loss setup - (even if the
					value of YFI dropped 1000x, the value of your staked tokens would only
					go down 13%) which became very attractive to LPs. 💋💋💋{" "}
				</div>
				<div>
					Liquidity providers started to deposit their DAI into the Balancer
					pool, which meant that they were effectively buying up YFI every time
					they entered the DAI/YFI pool.
				</div>
				<div style={{ fontSize: "2rem", textAlign: "center" }}>
					The value of YFI quickly jumped from $0 to $3000+ 💸💸💸
				</div>
				<div>
					And the farmers started to migrate to the YFI farming pools over a
					couple days creating a 3000% APy for those farmers. The peak TVL for
					Yearn was around $400 million. 😲
				</div>
				<img src={i2} alt="" />
				<div style={{ fontSize: "2rem", textAlign: "center" }}>
					Soon YFI clones started popping up, and all this hype was led by
					forking waifus.{" "}
				</div>
				<div>
					YFII, YYFI, YFFI, ASUKA, SIMP, YFT, and perhaps more used a similar
					set up - a yCRV staking pool for the initial distribution and a 98/2
					balancer pool for dmping their farmed tokens.{" "}
				</div>
				<div>
					Most could for more than a couple days but YFII generated enough buzz
					to attract more than $100 million TVL.
				</div>
				<div>
					Seeing this delicious farming opportunity😋, Andre created a delegated
					farming vault that farms and dumps YFII. gains from the YFII farm went
					to YFI holders, making YFI the ultimate farming token. 😤
				</div>
				<div>
					Whether you like the rampant increase in clone tokens or not, you have
					to appreciate{" "}
					<span
						style={{
							fontFamily: "inheret",
							fontStyle: "italic",
							background: "##fc0703",
							textTransform: "none",
						}}
					></span>
					how this is all analogous to the mining phase in 2017.
				</div>
				<div>
					Back in the days, GPU and CPU miners would simply fork BTC or ETH,
					announce it on{" "}
					<a className="textLink" href="bitcointalk.org">
						Bitcointalk.Org
					</a>{" "}
					thread, then claim that it is going to be the next big crypto. 🙃 Most
					miners were there only to farm for a couple days and dump them on poor
					speculative buyers.
				</div>
				<div>
					now the same thing is happening within the Ethereum chain - except
					this time you don't need hardware to join the game, only some capital
					and enough knowledge of DeFi. 🤓💅 Will this trend of forking keep up?
				</div>
				<div>
					I'm not sure. But at least we do know YFI and{" "}
					<span
						style={{
							fontFamily: "inheret",
							background: "#19731C",
							fontStyle: "italic",
							textTransform: "none",
						}}
					>
						Andre “Big PP” Cronje
					</span>{" "}
					are here to stay. His relentless pursuit for yield and permissionless
					sabotage 😤🔧 of other farms has shown how effective the yearn
					ecosystem is for generating profits with your stablecoins. And
					personally, I don’t think the waifu king is going to step down just
					yet…
				</div>
				<div style={{ textAlign: "center" }}>🤴⚔️</div>
				<img src={i3} alt="" />
			</div>
		</Fragment>
	);
}

export default Waifus_2;
