import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue4/latestage/1.jpg"
import i2 from "../../../../img/issues/issue4/latestage/2.jpg"
import i3 from "../../../../img/issues/issue4/latestage/3.jpg"
import i4 from "../../../../img/issues/issue4/latestage/4.jpg"
import i5 from "../../../../img/issues/issue4/latestage/5.jpg"
import i6 from "../../../../img/issues/issue4/latestage/6.jpg"
import i7 from "../../../../img/issues/issue4/latestage/7.jpg"
import i8 from "../../../../img/issues/issue4/latestage/8.jpg"
import i9 from "../../../../img/issues/issue4/latestage/9.jpg"
import i10 from "../../../../img/issues/issue4/latestage/10.jpg"
import i11 from "../../../../img/issues/issue4/latestage/11.jpg"
import i12 from "../../../../img/issues/issue4/latestage/12.jpg"
import i13 from "../../../../img/issues/issue4/latestage/13.jpg"
import i14 from "../../../../img/issues/issue4/latestage/14.jpg"
import i15 from "../../../../img/issues/issue4/latestage/15.jpg"
import i16 from "../../../../img/issues/issue4/latestage/16.jpg"
import i17 from "../../../../img/issues/issue4/latestage/17.jpg"
import i18 from "../../../../img/issues/issue4/latestage/18.jpg"
import i19 from "../../../../img/issues/issue4/latestage/19.jpg"

function Latestage_4() {

	return (
		<Fragment>
			<h1 className="articleTitle">Late Stage 2020</h1>
			<div id="h">Schitzotopia</div>
			<br />
			<div id="h">November 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
           <div style={{color:"red", textAlign:"center", fontSize:"1.8rem"}}> <span className="leadLetter" style={{color:"red"}}>G</span>arbage time is running out. Can what's playing you make it to 2021?</div>
		   <br/>
		  <img src={i1} alt=""/>
		  <img src={i2} alt=""/>
		  <img src={i3} alt=""/>
		  <img src={i4} alt=""/>
		  <img src={i5} alt=""/>
		  <img src={i6} alt=""/>
		  <img src={i7} alt=""/>
		  <img src={i8} alt=""/>
		  <img src={i9} alt=""/>
		  <img src={i10} alt=""/>
		  <img src={i11} alt=""/>
		  <img src={i12} alt=""/>
		  <img src={i13} alt=""/>
		  <img src={i14} alt=""/>
		  <img src={i15} alt=""/>
		  <img src={i16} alt=""/>
		  <img src={i17} alt=""/>
		  <img src={i18} alt=""/>
		  <img src={i19} alt=""/>
			</div>
		</Fragment>
	);
}

export default Latestage_4;
