import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue4/clairaudience/1.gif"

function Clairaudience_4() {
	return (
		<Fragment>
			<h1 className="articleTitle">Onmilingual Clairaudience</h1>
			<div id="h">Amanda Devik</div>
			<br />
			<div id="h">November 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<img src={i1} alt=""/>
				<a
					className="raribleButtonArticle"
					style={{ alignSelf: "center", zIndex: "5" }}
					href="https://app.rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:82644:0x91c5a441fff14834925ef5224dcf1f3d110e50bf"
				>
					{`RARIBLE`}
				</a>
				<br/>
				<div style={{textAlign:"center", fontWeight:"bold"}}>Hauntologies</div>
				<div><span className="leadLetter">P</span>ixel art has been strongly associated with nostalgia as technology has advanced. Because it is such a powerful medium with many subsets, I have resolved to focus on developing my skills within the niche of pixel art! Working with pixels, the end result is produced after meticulously placing individual pixels. The aspect of “seeing the bigger picture” often times is not revealed until you zoom out from the microcosm of the individual pixel and see the piece in it’s entirety. I love how this aspect of the style fits into the theme of looking at the greater whole of the crypto community. This artwork is not “pure” pixel art, it’s a mix of digital styles.</div>
				<div>An interesting aspect to this piece is that because the featured pair of dolphins are produced by pixel, the nostalgic quality adds weight to the longing of the border dolphins. I believe we’ve all at some point in our lives experienced a sort of longing, feeling trapped in circumstance and unable to connect. This certainly rings true amongst members of the crypto community. Whether it’s inability to connect with our loved ones about the topic or actively seeking out friends or a partner that understands, the feeling of separation is a real and prevalent subject. When we do find friends that are on the same wavelength, it’s such a beautiful thing! I wanted to celebrate these feelings of connecting with like-minded individuals in the crypto space. The border was commissioned by another artist and balances the work as a whole.</div>
				<div>The original topic for this piece was “psychedelic dolphins fed LSD in order to gain the ability of communicating with humans.” Though the art does not directly illustrate this message, the subject matter of longing for connection and celebration in finally attaining it is transmittable no matter what species you are or what language you speak.</div>
				<div style={{textAlign:"center", fontWeight:"bold"}}>About the Artist</div>
				<div>Amanda Devik is a crypto enthusiast, stay at home mom, NFT artist on the Ethereum platform, and gardener. She is an active member of the DigiByte community, advocating for expanding the use of DigiAssets into the crypto art scene. “Omnilingual Clairaudience” features a pair of dolphins in blissful communication as solitary dolphins look on, trapped in their excluded isolation.</div>
				<a style={{marginBottom:"10px", textTransform:"lowercase", textAlign:"center", color:"lightgoldenrodyellow"}} href="https://app.rarible.com/aedartworks/created">app.rarible.com/aedartworks/created</a> <br/>
				<a style={{marginBottom:"10px", textTransform:"lowercase", textAlign:"center", color:"lightgoldenrodyellow"}} href="https://app.rarible.com/poplollis/onsale">app.rarible.com/poplollis/onsale</a> <br/>
				<a style={{marginBottom:"10px", textTransform:"lowercase", textAlign:"center", color:"lightgoldenrodyellow"}} href="https://twitter.com/amanda_devik">twitter.com/amanda_devik</a> <br/>
				<a style={{marginBottom:"10px", textTransform:"lowercase", textAlign:"center", color:"lightgoldenrodyellow"}} href="https://facebook.com/amanda.devik">facebook.com/amanda.devik</a>
			</div>
		</Fragment>
	);
}

export default Clairaudience_4;
