import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue4/rugs/1.jpg"
import i2 from "../../../../img/issues/issue4/rugs/2.jpg"
import i3 from "../../../../img/issues/issue4/rugs/3.jpg"
import i4 from "../../../../img/issues/issue4/rugs/4.jpg"
import i5 from "../../../../img/issues/issue4/rugs/5.jpg"

function Rugs_4() {
	return (
		<Fragment>
			<h1 className="articleTitle">On Rugs</h1>
			<div id="h">@Hardwood_</div>
			<br />
			<div id="h">November 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
                <div><span className="leadLetter">T</span>o wound the autumnal – nah fuck that. I'm Hardwood.</div>
                <div>My cetacean editors at Shitcoin Praxis suggested that I cover my “branding” in this issue. But really, what use is a brand, or a name? Many already know me for the gay, crude denizen of Crypto Twitter that I am. I thought long and hard –  ultimately, fruitlessly – about this.</div>
                <div>Good wood needs no bush. The deadline for my submission is at hand, so in classic crypto fashion, I've decided to rug the dolphins and write about something else.</div>
                <img src={i1} alt="" />
                <div>Let's talk about rugs. The term “rugpull”, sexual as it may sound, refers to the act of dumping an overwhelming amount of tokens into a liquidity pool. In simple terms: grabbing all the money on the table and absconding. Envision a cartoon character pulling the rug out from under another – the base deception of the act, the adversarial nature of DeFi (Decentralized Fellatio) are masked by the word's inherent absurdity and goofy humor. Swept under the rug, you might say. In recent days, however, usage of this term has expanded within DeFi circles to refer to almost anything. Team took the presale and ran? Rug. VCs dumped their coins? Rug! Devs died? Bullish; wen rug?</div>
                <img src={i2} alt="" />
                <div>“Wen rug”, as if. The thinking man, homo sapiens, is a species specialized for conflict. The wars of our age are fought with ideas – but they always have been. People have been getting ruined financially for as long as the rug called “finance” has existed – ShellToken, BitCattle, SilverCoin. We've been getting rugged out of house and home since property was invented. The land you sleep on, the things you own, your rights as a human – represent a rug that can be pulled from under your feet. Anything you own is something that can be taken from you through force or through finesse.</div>
                <div>Perhaps the most beautiful and insidious rug of all are the rugs that rug themselves - Evil. The idea – the rug – that Reason and Force should be distilled into separate domains, parallel to Civilization and Savagery, is effective, necessary and palatable. So effective, so all-encompassing is this rug that even soldiers – fighters and killers by trade – have trouble shooting to kill. Empathy limits us, conditions us against behavior potentially destructive to other humans. The excellence to kill has been bred out of the modern man. Why would you rug Hardwood, if he were just another guy like yourself?</div>
                <div>Because we are not alike. I am me, and my wallet is mine; you are you, and your wallet is yours. (For now.)</div>
                <img src={i3} alt="" />
                <div>Life itself is an adversarial existence. Man lives by the rug and dies by the rug. We've been rugging since we were apes. As animals – living things – we survive through conflict with other living things. We harvest, plunder the bodies of prey to feed the fires of our own lives. Man is an animal, a walking vehicle that captures nutrients, masticates them in his maw of enameled teeth so they may be broken down to build his being.</div>
                <div>To not rug is to go against nature. Everything is a rug, including this statement. To those who don't rug, I spit.</div>
                <div>Let me be clear: I am not advocating a world without laws. Far be it from me to suggest such a thing. Laws, including the unspoken ones, are the rugged foundations, the rugs of wood and stone, upon which we build the rugs of our lives and our aspirations, in the hopes that they might take us to the resplendent Moon.</div>
                <div>More than laws, standards govern even in an unregulated free market. Crypto is an insider's game, one of circles within circles, rugs on rugs. To name a few: free lunch over fair launch. Perceived fairness over fairness. Transparency – the idea that what you get what's on the tin, even if what you open up is a can of worms. And like all other fight clubs, don't talk about fight club. Never call a man out on his rug, or the world will know you're gay for looking at his dick.</div>
                <div>Well, fuck the rules, fuck the agenda, and fuck me in the ass; I'm gay.</div>
                <img src={i4} alt="" />
                <div>I have come to to wound the autumnal CT, so Hardwood_ is my howl. Hardwood_ is the reflection of a deeply lonely probably depressed chronically frustrated degenerate mind. I am a rugging, LARPing, self-centered, retarded gay anime man on the Tweeter app. If you like that, stick around, and let's rug each other until we're sick of it.</div>
                <div>I am a rugged man who is the great-great-grandson of a man who was rugged. Who I am matters little. Like all those who rug, I weave my rugs and I pull them everyday so that I might have the chance to tug finer rugs, so that I might sleep on warmer, more comfortable rugs. Let my rugs speak for me, whether they end as soggy cardboard on the street, stained cloth, or fine art.</div>
                <div>To the rug pullers, rug weavers, painters, inspectors, commissioners, connoisseurs, collectors, salesmen and customers: I salute you from a dirty mat in a ghetto. Through our rugs, be they the degenerate's cloak, the blackhat's mask, or the hero's flag, we weave our tales indelibly into the tapestry of time.</div>
                <div>Or maybe we're all just a bunch of ugly horny nerds. Fuck knows.</div>
                <img src={i5} alt="" />
			</div>
		</Fragment>
	);
}

export default Rugs_4;
