import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue4/karen/1.jpg"

function Karen_4() {
	return (
		<Fragment>
			<h1 className="articleTitle">Dear DeFi Karen</h1>
			<div id="h">Defi Karen</div>
			<br />
			<div id="h">November 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<img src={i1} alt="" />
				<div style={{color:"red", fontSize:"1.8rem", textAlign:'center'}}>SUBMIT TO KAREN</div>
				<div style={{textAlign:'center'}}><span className="leadLetter">A</span>nnouncing my Dear DeFi Karen section.</div>
				<div>(Coral Gables, Florida - November 2nd, 2020) - As it is of public knowledge I have been dominating the DeFi space, trying to impose law & order for some months now.</div>
				<div>Let me make this very clear: DeFi is PERMISIONLESS BUT NOT MANAGERLESS. There is only ONE person in the whole of Ethereum who knows the managers and who the community needs to escalate absolutely all of the situations to. </div>
				<div>Now to drive the point further: no token exists if I don’t have an allocation. Should a token exist that has decided to not include DeFi Karen in their VIP list, that token should consider itself practically seized by the SEC, for Karen will not let a cop be uncalled, a developer unreported and leave a company without a lawsuit, let alone multiple.</div>
				<div>If you don’t want me to call the cops, you should also understand that I DECIDE WHAT TO REPORT. There is no such thing as an on-demand DeFi Karen. You can summon DeFi Karen, but if DeFi Karen is interrupted while at the weekly manipedis with SBF Alameda, or worse, should you attempt to disturb my biweekly Pina Coladas with Elizabeth Stark (I need to keep Bitcoin from scaling so interrupting Lightning is a core Karen task) you will end up in Guantanamo DO I MAKE MYSELF CLEAR?</div>
				<div>Now get off my property or I will call the FBI.</div>
				<div>With that said, I am looking forward to arbitrarily escalate different concerns in DeFi, should you see a project not considering me as a thought leader - you will report them to me and call some cops in the meantime.</div>
				<div>One final note to Robert - I AM STILL WAITING FOR MY $COMP REFUND I BOUGHT AT ATH AND I DON’T AGREE WITH THIS.</div>
				<br/>
				<div style={{color:"lightblue"}}>Dear DeFiKaren,</div>
				<div style={{color:"lightblue"}}>I really need some advice and might need you to call a manager. </div>
				<div style={{color:"lightblue"}}>My friends keep inviting me to go swimming in this thing called a pool 2 but I really don’t know how to swim. I heard some other kids got rekt and drowned in the pool recently but my friends swear I’ll be fine and this is the path to foodcoin glory. They’re not really good friends and I feel a lot of pressure but I’m new and I want to fit in. What do I do?</div>
				<br/>
				<div style={{color:"lightcyan"}}>Hello Anon,</div>
				<div style={{color:"lightcyan"}}>First things first, call the cops.  These people aren't your friends. Pool 2 gets everyone and everything rekt. </div>
				<div style={{color:"lightcyan"}}>Second thing to know is that only confirmed swimmers can enter Pool 2. Do you have a certificate? I didn't think so. Your friends are bad for pressuring you into deep DeFi waters and when the cops arrive they'll probably find drugs on them. </div>
				<div style={{color:"lightcyan"}}>Hold on, brb, there are youths on my lawn...</div>
				<div style={{color:"lightcyan"}}>They were Mormons, they tried to give me a bible. I sent them away with a copy of "How to DeFi" instead and told them they needed to buy a $FARM and $PICKLE if they wanted salvation.  They work for Karen now. </div>
				<div style={{color:"lightcyan"}}>Where was I? Oh yes, Pool 2. Stay out, get yourself some DAI-ETH and DAI-USDC and make sure you're always the first in. Then dump on the people in Pool 2, Sassal style.</div>
				<div style={{color:"lightcyan"}}>Sincerely,</div>
				<div style={{color:"lightcyan"}}>DeFi Karen </div>
			</div>
		</Fragment>
	);
}

export default Karen_4;
