import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue2/decentralization/1.webp";
import i2 from "../../../../img/issues/issue2/decentralization/2.webp";
import i3 from "../../../../img/issues/issue2/decentralization/3.webp";
import i4 from "../../../../img/issues/issue2/decentralization/4.webp";
import i5 from "../../../../img/issues/issue2/decentralization/5.webp";
import i6 from "../../../../img/issues/issue2/decentralization/6.webp";
import i7 from "../../../../img/issues/issue2/decentralization/7.webp";

function Decentralization_2() {
	return (
		<Fragment>
			<h1 className="articleTitle">Decentralization is Fake and Gay</h1>
			<div id="h">realCY199</div>
			<br />
			<div id="h">August 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div style={{ textAlign: "center", fontStyle: "italic" }}>
					<span className="leadLetter">A</span>hhh, decentralization. It’s the
					reason we’re all here, right? Take control of your money! No more
					middlemen! The next stage of capitalism! New paradigm! Lightning bolt
					⚡️⚡️⚡️!
				</div>
				<div>
					Screenshot 2020-08-19 at 02.44.46.png Unfortunately, dear reader, I
					have some bad news. The only thing faker than LARPing Dungeons &
					Dragons in the woods is the notion that the Lightning Network will
					meaningfully scale bitcoin. And the only thing faker than that is the
					idea of decentralization itself.
				</div>
				<div>
					Power laws are deeply embedded in nature. For virtually any¹
					real-world phenomenon², you’ll find a Pareto-like distribution³. You
					know, the 80/20 rule - 80% of the output in a system comes from 20% of
					that system’s inputs, and the distributions are often significantly
					more lopsided than 80/20. Hell, even insects follow these laws. It
					doesn’t take a genius to conclude that centralization of
					power/wealth/control/etc. is an inevitable outcome for any system
					where a minority of participants receive the vast majority of the
					system’s rewards (which is almost every system).
				</div>
				<div>
					Or, they can skip the work/accumulation phase and receive the rewards
					on day one.
				</div>
				<div style={{ textAlign: "center", fontSize: ".75rem" }}>
					¹{" "}
					<a
						className="textLink"
						href="https://rss.onlinelibrary.wiley.com/doi/full/10.1111/j.1740-9713.2014.00725.x"
					>
						https://rss.onlinelibrary.wiley.com/doi/full/10.1111/j.1740-9713.2014.00725.x
					</a>
				</div>
				<div style={{ textAlign: "center", fontSize: ".75rem" }}>
					²{" "}
					<a
						className="textLink"
						href="https://en.wikipedia.org/wiki/Pareto_distribution#Occurrence_and_applications"
					>
						https://en.wikipedia.org/wiki/Pareto_distribution#Occurrence_and_applications
					</a>
				</div>
				<div style={{ textAlign: "center", fontSize: ".75rem" }}>
					³{" "}
					<a
						className="textLink"
						href="https://steemit.com/steemstem/@zycr22/not-so-busy-ants-and-the-pareto-principle"
					>
						https://steemit.com/steemstem/@zycr22/not-so-busy-ants-and-the-pareto-principle
					</a>
				</div>
				<img src={i1} alt="" />
				<div>
					Even (or maybe especially) for those of us who have been in the thick
					of crypto for years, it’s easy to be seduced by our own bullshit. Name
					the metric, and I’ll tell you why it’s centralized. The following
					charts speak for themselves:
				</div>
				<img src={i2} alt="" />
				<img src={i3} alt="" />
				<div>
					ETH and BTC wealth holdings are more unequal than the most corrupt,
					kleptocratic countries on Earth⁴⁵. I’ve met many of the biggest
					holders of BTC and ETH, and they’re almost all batshit crazy. I don’t
					know about you, but if I had to choose my oligarchs, I’ll take Warren
					Buffett over Justin Sun and Bill Gates over Barry Shillbert any day of
					the week.
				</div>
				<div style={{ textAlign: "center", fontSize: ".75rem" }}>
					⁴{" "}
					<a
						className="textLink"
						href="https://cointelegraph.com/news/bitcoin-wealth-inequality-drops-in-2019-unlike-ether-litecoin-report"
					>
						https://cointelegraph.com/news/bitcoin-wealth-inequality-drops-in-2019-unlike-ether-litecoin-report
					</a>
				</div>
				<div style={{ textAlign: "center", fontSize: ".75rem" }}>
					⁵{" "}
					<a
						className="textLink"
						href="https://worldpopulationreview.com/country-rankings/gini-coefficient-by-country"
					>
						https://worldpopulationreview.com/country-rankings/gini-coefficient-by-country
					</a>
				</div>
				<img style={{ marginBottom: "0" }} src={i4} alt="" />
				<div
					style={{
						textAlign: "center",
						marginTop: "5px",
						// zIndex: "5",
						fontSize: ".9rem",
					}}
				>
					An early BTC investor spotted in the wild.
				</div>
				<div>
					You can fit the Chinese miners who control >50% of BTC’s hashrate onto
					a queen size bed together. They wouldn’t even have to lie on top of
					each other (granted, the Chinese tend to be a bit shorter than
					average), but I have no doubt there’d be plenty of colluding beneath
					the sheets. Ditto for ETH, except it’d be two guys in a twin bed. The
					time when the veil came closest to being pulled back on this was after
					Binance got hacked last year - CZ publicly deliberated on Twitter⁶
					about bribing his miner amigos to undo a $40 million hack (and he
					easily could have), but decided against it, because it might “damage
					BTC’s credibility.” You don’t say.
				</div>
				<div>
					What about core developer activity? With regard to BTC, a couple
					neckbeards at Bitcoin Core call the shots. In ETH’s case, there are a
					whopping 4 people on the Geth team. Feeling a sudden urge to ban
					buses⁷, ETH holders? What’s that? ETH2 is decentralized and will
					change everything, you say? It has 10 whole client teams? Maybe I’ll
					be proven wrong after all when it ships phase 2 in 2040.
				</div>
				<div style={{ textAlign: "center", fontSize: ".75rem" }}>
					⁶{" "}
					<a
						className="textLink"
						href="https://twitter.com/cz_binance/status/1125996194734399488"
					>
						https://twitter.com/cz_binance/status/1125996194734399488
					</a>
				</div>
				<div style={{ textAlign: "center", fontSize: ".75rem" }}>
					⁷{" "}
					<a
						className="textLink"
						href="https://en.wikipedia.org/wiki/Bus_factor"
					>
						https://en.wikipedia.org/wiki/Bus_factor
					</a>
				</div>
				<img src={i5} alt="" />
				<div>
					I could go on, but you get the point. So, does this mean blockchains
					are doomed and we should all go back to using AWS, where Lord Bezos
					will let you sow his server farm like a good little serf? No. No it
					doesn’t. Because I have more news for you, dear reader: the world
					largely runs on bullshit and memes. When everyone pretends that
					something is true, even if it isn’t, it actually *becomes* true in a
					collectively deluded sort of way. Like sending crypto to your favorite
					shitlord dissident and not having an SJW payment processor block it,
					or having routine sex with your wife, but only getting off when you
					close your eyes and imagine you’re on an opulent bed in a far away
					land, servers humming warmly in the background, feeling things you’ve
					never felt before with a few{" "}
					<span style={{ font: "inherit", fontStyle: "italic" }}>
						very coordinated
					</span>{" "}
					Chinese men.
				</div>
				<img src={i6} alt="" />
				<div style={{ textAlign: "center" }}>Front-run those DEX's Wang!</div>
				<img src={i7} alt="" />
				<div style={{ textAlign: "center" }}>
					Come drill some buttcoins with us, big boy.
				</div>
			</div>
		</Fragment>
	);
}

export default Decentralization_2;
