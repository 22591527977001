import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue4/incelism/1.jpg"
import i2 from "../../../../img/issues/issue4/incelism/2.jpg"
import i3 from "../../../../img/issues/issue4/incelism/3.jpg"
import i4 from "../../../../img/issues/issue4/incelism/4.jpg"
import i5 from "../../../../img/issues/issue4/incelism/5.jpg"
import i6 from "../../../../img/issues/issue4/incelism/6.jpg"
import i7 from "../../../../img/issues/issue4/incelism/7.jpg"

function Women_4() {
	return (
		<Fragment>
			<h1 className="articleTitle">Female Incelism</h1>
			<div id="h">CetaceanFutures411</div>
			<br />
			<div id="h">November 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
                <div style={{color:"purple", fontSize:"1.8rem", textAlign:"center"}}>"There does exist another type of creature out there, a rare specimen infrequently discussed, known only as, The Female Incel."</div>
                <img src={i1} alt="" />
                <div><span className="leadLetter">S</span>o, what if you're a woman in crypto and you aren't a WIB, professional crypto sugar baby or a "wretched Anastasia"? What is your fate then? Well, dear reader, let me tell you about the lonely life of a successful female crypto entrepreneur a.k.a. the female crypto incel. </div>
                <div>First of all, if you've had success in either building, trading or investing in blockchain networks, you probably have a higher than average IQ, high enough to intimidate almost all crypto bros - the only fuckable male species in crypto land. If you fortunately have a high tolerance for spiritual bullshit, tantric sex, and are able to turn a blind eye or to even to enjoy Epstein's extended network and crypto sex cults (hello, TLC), and shameless delusional EOS shills, you can also try your luck with crypto burners. </div>
                <img src={i2} alt=""/>
                <div>All this already makes it difficult to find a suitable mate, but don't forget that your entire life tends to be based around crypto and blockchain networks- your addiction to shitcoins tends to consume the greater part of your life to a point that in seems even inconceivable to spend the majority of your time around someone who doesn't understand or even know what you are talking about. Try explaining your farming, shitcoin strategies and philosophies to a normie boy and watch their eyes glaze over. </div>
                <div>Rejection and perpetual loneliness is a common theme in your life, no matter how hard you try. Few understand how hard life is for female incels - to be socially accepted and make friends, you spend all your free time brushing up on Hentai and have mastered LARPing as a BBC with your alt discord identity and on 4chan. You are particularly attracted to struggling shitcoin founders with deep character flaws, and your special way of expressing your love to them is to pump their shitcoin hard, with your capital and passionate shill, only to be romantically rejected by them in the end even you pumped their shitcoin 1000x. They won't even spare you a vial of frozen sperm in exchange for your love, effort and support. Deep down you are secretly so envious of the WIB - why do they get 100x more sex and sperms than you? Clearly I should have been pumping something other than their shitcoin.</div>
                <img src={i3} alt=""/>
                <div>Another point important to mention is the paranoia that prevents you from opening up your life to new people that's a part of being a large holder of cryptographic digital assets. No banks and no middlemen means the buck stops with you- you're responsible for your own security. Makes you think twice about taking home a tech bro when you're farming shitcoins on a Chinese website with no SSL via Metamask with the equivalent of his annual salary. </div>
                <div>There are other crypto native men like you, but most successful crypto native men tend to have psychological issues and are probably as autistic as you are and work best as friends and sperm donors. You may have already tried dating other incel autistic NEETs just like you, but quickly realized that these men all have deep mental and psychological issues and that attending to their needs is a full time job you don't have time for. The idea of them being your life partner quickly becomes scary, you realized that what you need after all, is only their genetic materials so you can mass produce autistic babies with Eastern European imported surrogate mothers.</div>
                <div>Lastly, with the crypto space being so small, are there any female crypto natives who aren't eskimo sisters many times over with so few attractive men in the space? At the end of the day, most crypto native men would rather be with a WIB or "wretched Anastasia" than you- you can even pump their shitcoin 1000x as a true expression of love and still be rejected. </div>
                <div>So, what's a girl to do, on her way to building a crypto empire that she wants to leave to someone one day? </div>
                <div style={{ fontSize:"1.8rem", textAlign:"center"}}>Enter the Dolphin</div>
                <img src={i4} alt=""/>
                <div>There is another option for "girls like us" - dolphin eugenics. Why not let a dolphin decide on your best genetic match? Maybe the best person for you to reproduce with isn't who you'd be happy spending the majority of your time with- cetacean run, non NPC, high IQ sperm banks are the solution. The choice should just be left to the higher consciousness of an other worldly cetacean hive mind. No humans, no banks, no middlemen, just DAO governed and cetacean run tokenized sperm banks and markets. </div>
                <div>What are you waiting for?</div>
                <img src={i5} alt=""/>

                <img src={i6} alt=""/>

                <img src={i7} alt=""/>


			</div>
		</Fragment>
	);
}

export default Women_4;
