import React, { Fragment } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./css/App.scss";
import "./components/Navbar";
import Navbar from "./components/Navbar";
import Praxis from "./components/pages/Praxis";
import Issues from "./components/pages/Issues";
import Gallery from "./components/pages/Gallery";
import Submit from "./components/pages/Submit";
import Home from "./components/pages/Home";
import ISSUE_4 from "./components/pages/issues/Issue_4";
import ISSUE_3 from "./components/pages/issues/Issue_3";
import ISSUE_2 from "./components/pages/issues/Issue_2";
import ISSUE_1 from "./components/pages/issues/Issue_1";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import iData from "./store/IssueData";
import Bondage_1 from "./components/pages/issues/issue_1/Bondage_1";
import Szabo_1 from "./components/pages/issues/issue_1/Szabo_1";
import Degen_1 from "./components/pages/issues/issue_1/Degen_1";
import Polkaheads_1 from "./components/pages/issues/issue_1/Polkaheads_1";
import Femdom_1 from "./components/pages/issues/issue_1/Femdom_1";
import Yield_1 from "./components/pages/issues/issue_1/Yield_1";
import Waifus_2 from "./components/pages/issues/issue_2/Waifus_2";
import Slave_2 from "./components/pages/issues/issue_2/Slave_2";
import Decentralization_2 from "./components/pages/issues/issue_2/Decentralization_2";
import NFT_2 from "./components/pages/issues/issue_2/NFT_2";
import Memes_2 from "./components/pages/issues/issue_2/Memes_2";
import Polkadot_3 from "./components/pages/issues/issue_3/Polkadot_3";
import Sushi_3 from "./components/pages/issues/issue_3/Sushi_3";
import Tendies_3 from "./components/pages/issues/issue_3/Tendies_3";
import Chives_3 from "./components/pages/issues/issue_3/Chives_3";
import Godfather_3 from "./components/pages/issues/issue_3/Godfather_3";
import Kulupu_3 from "./components/pages/issues/issue_3/Kulupu_3";
import Music_3 from "./components/pages/issues/issue_3/Music_3";
import River_4 from "./components/pages/issues/issue_4/River_4";
import Latestage_4 from "./components/pages/issues/issue_4/Latestage_4"
import Clairaudience_4 from "./components/pages/issues/issue_4/Clairaudience_4"
import Eugenic_4 from "./components/pages/issues/issue_4/Eugenic_4"
import Incelism_4 from "./components/pages/issues/issue_4/Incelism_4"
import Intervention_4 from "./components/pages/issues/issue_4/Intervention_4"
import Karen_4 from "./components/pages/issues/issue_4/Karen_4"
import Libidinal_4 from "./components/pages/issues/issue_4/Libidinal_4"
import Rugs_4 from "./components/pages/issues/issue_4/Rugs_4"
import Women_4 from "./components/pages/issues/issue_4/Women_4"

function App() {
	return (
		<Router>
			<Navbar />
			<Route
				render={({ location, match }) => (
					<TransitionGroup appear={true}>
						<CSSTransition key={location.key} timeout={600} classNames="fade">
							<Switch location={location}>
								<Route path="/issues/:id/:article">
									<div className="page">
										<NavArticles />
									</div>
								</Route>
								<Route path="/issues/:id">
									<div className="page">
										<NavIssues />
									</div>
								</Route>
								<Route path="/:id">
									<div className="page">
										<Navigate />
									</div>
								</Route>
								<Route exact path="/">
									<div className="page">
										<Home />
									</div>
								</Route>
							</Switch>
						</CSSTransition>
					</TransitionGroup>
				)}
			/>
		</Router>
	);
}

function Navigate() {
	const components = {
		Praxis: Praxis,
		Issues: Issues,
		Gallery: Gallery,
		Submit: Submit,
	};
	let { id } = useParams();
	let nId = id.slice(0, 1).toUpperCase() + id.slice(1).toLowerCase();
	let ele = null;
	if (components[nId]) {
		ele = React.createElement(components[nId]);
	} else {
		ele = React.createElement(Home);
	}
	return <div className="page">{ele}</div>;
}

function NavIssues() {
	const components = {
		1: ISSUE_1,
		2: ISSUE_2,
		3: ISSUE_3,
		4: ISSUE_4,
	};
	let { id } = useParams();
	let ele = null;
	if (components[id]) {
		ele = React.createElement(components[id], { key: id });
	} else {
		ele = React.createElement(Issues);
	}
	return <div className="page">{ele}</div>;
}

function NavArticles() {
	const issueComponents = {
		1: ISSUE_1,
		2: ISSUE_2,
		3: ISSUE_3,
		4: ISSUE_4,
	};
	const components = {
		1: {
			bondage: Bondage_1,
			szabo: Szabo_1,
			degen: Degen_1,
			polkaheads: Polkaheads_1,
			femdom: Femdom_1,
			yield: Yield_1,
		},
		2: {
			waifus: Waifus_2,
			slave: Slave_2,
			decentralization: Decentralization_2,
			nft: NFT_2,
			memes: Memes_2,
		},
		3: {
			polkadot: Polkadot_3,
			sushi: Sushi_3,
			tendies: Tendies_3,
			chives: Chives_3,
			godfather: Godfather_3,
			kulupu: Kulupu_3,
			music: Music_3,
		},
		4: {
			river: River_4,
			latestage: Latestage_4,
			clairaudience: Clairaudience_4,
			eugenic: Eugenic_4,
			incelism: Incelism_4,
			intervention: Intervention_4,
			karen: Karen_4,
			libidinal: Libidinal_4,
			rugs: Rugs_4,
			women: Women_4
		},
	};
	let { id, article } = useParams();
	let ele = null;
	if (components[id]) {
		if (components[id][article]) {
			ele = React.createElement(components[id][article], { key: article });
		} else {
			ele = React.createElement(issueComponents[id], { key: id });
		}
	} else {
		ele = React.createElement(Issues);
	}
	return <div className="page">{ele}</div>;
}

export default App;
