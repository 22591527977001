import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue2/slave/1.webp";
import i2 from "../../../../img/issues/issue2/slave/2.webp";
import i3 from "../../../../img/issues/issue2/slave/3.webp";

function Slave_2() {
	return (
		<Fragment>
			<h1 className="articleTitle">Are You a Slave to Your Shitcoin?</h1>
			<div id="h">cogdiss</div>
			<br />
			<div id="h">August 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<img src={i1} alt="" />
				<div
					style={{
						textAlign: "center",
						fontSize: "1.8rem",
						fontStyle: "italic",
					}}
				>
					Are you a founder being enslaved by your shitcoin community?
				</div>
				<div>
					<span className="leadLetter">I</span>n a dark dystopian future not too
					far away, after the complete breakdown of western civilization and
					financial system, we will enter a period of cyber pastoral patchwork
					tribalism, made up of hives of shitcoin communities with their
					enslaved founders/leaders rapidly producing, supporting, and pumping
					shitcoins at the center. In this new reality, every shitcoin must
					become a cult.
				</div>
				<div>
					Members of these tribal shitcoin communities need the founding team to
					keep producing and maintaining their shitcoins, supplementary
					materials and memes so community members are incentivized to keep them
					alive.
				</div>
				<div
					style={{
						textAlign: "center",
						color: "red",
						fontStyle: "italic",
						fontSize: ".9rem",
					}}
				>
					Have to keep the pump alive.Have to keep the pump alive.Have to keep
					the pump alive.Have to keep the pump alive.Have to keep the pump
					alive.Have to keep the pump alive.Have to keep the pump alive.Have to
					keep the pump alive.Have to keep the pump alive.
				</div>
				<div>
					But, if they disappoint the community one too many times, it's off
					with their heads.
				</div>
				<div>
					Memes, shills, and pumps are a coordinated effort to enslave poor
					Sirgays into sacrificing their life, time and energy to the cult of
					the shitcoin.
				</div>
				<div>
					If you want freedom then you’ll have to be poor, like poor Andre, or
					remain anon like the Based Ghouls, and burn your accidental premine.
					The path to freedom is based and brutal.
				</div>
				<div>So, better get ready to shill for your life, pump or die!</div>
				<img src={i2} alt="" />
				<img src={i3} alt="" />​
			</div>
		</Fragment>
	);
}

export default Slave_2;
