import React, { Fragment } from "react";
import "../../css/components/pages/Gallery.scss";
import art1 from "../../img/gallery/gallery_1.jpeg";
import art2 from "../../img/gallery/gallery_2.jpeg";
import art3 from "../../img/gallery/gallery_3.jpeg";
import art4 from "../../img/gallery/gallery_4.jpeg";
import art5 from "../../img/gallery/gallery_5.jpeg";
import eye from "../../img/eyepyramid.png";

function Gallery() {
	return (
		<Fragment>
			<h1>CONSUME OUR NFT</h1>
			{/* <br />
			<img height="25px" src={eye} alt="" />

			<br />
			<br />
			<br /> */}
			<div id="galleryContainer">
				<div className="artpiece">
					<img src={art1} alt="" />
					<div className="artShowcase"></div>
					<div className="issuanceText">1 of 10</div>
					<div className="artText">The Dark Forest</div>
					<a
						className="nftButton"
						id="nftButtonId"
						style={{ alignSelf: "center" }}
						href="https://app.rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:39429:0x91c5a441fff14834925ef5224dcf1f3d110e50bf"
					>
						{`RARIBLE`}
					</a>
				</div>
				<div className="artpiece">
					<img src={art2} alt="" />
					<div className="artShowcase"></div>
					<div className="issuanceText">1 of 10</div>

					<div className="artText">Just Say Kulupu!</div>
					<a
						className="nftButton"
						id="nftButtonId"
						style={{ alignSelf: "center" }}
						href="https://app.rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:39441:0xa577edbd1413f070733602d2baf78a4861c5d8d0"
					>
						{`RARIBLE`}
					</a>
				</div>
				<div className="artpiece">
					<img src={art3} alt="" />
					<div className="artShowcase"></div>
					<div className="issuanceText">1 of 10</div>

					<div className="artText">Polkadot Bushido</div>
					<a
						className="nftButton"
						id="nftButtonId"
						style={{ alignSelf: "center" }}
						href="https://app.rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:39436:0x9be6a134b409b718a19bc895d5d5939ae0e24ba8"
					>
						{`RARIBLE`}
					</a>
				</div>
				<div className="artpiece">
					<img src={art4} alt="" />
					<div className="artShowcase"></div>
					<div className="issuanceText">1 of 10</div>

					<div className="artText">Never Say Never</div>
					<a
						className="nftButton"
						id="nftButtonId"
						style={{ alignSelf: "center" }}
						href="https://app.rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:39431:0xb3e8d332ef4730e6e6d37ba9885edd515506b19f"
					>
						{`RARIBLE`}
					</a>
				</div>
				<div className="artpiece">
					<img src={art5} alt="" />
					<div className="artShowcase"></div>
					<div className="issuanceText">1 of 10</div>

					<div className="artText">Battle Royale</div>
					<a
						className="nftButton"
						id="nftButtonId"
						style={{ alignSelf: "center" }}
						href="https://app.rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:39440:0x91c5a441fff14834925ef5224dcf1f3d110e50bf"
					>
						{`RARIBLE`}
					</a>
				</div>
			</div>
		</Fragment>
	);
}

export default Gallery;
