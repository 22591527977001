import React from "react";
import { Link } from "react-router-dom";
import dolphin from "../../img/dolphindance.gif";
import clicc from "../../img/dolphinpenis (1) (1) (1).png";

function Home() {
	return (
		<div id="homeContainer">
			<div className="showcaseText">
				S
				<span className="showcaseText" id="h">
					h
				</span>
				ITCOIN
			</div>
			<div className="showcaseText">PRAXIS</div>

			<br />
			<div id="danceContainer">
				<img className="danceDolphin1" height="350px" src={dolphin} alt="" />
				<div>
					<Link to="/issues/4" id="latestIssueHome">{`>>Read Issue #4<<`}</Link>
					<div>
						<img id="clicc" src={clicc} alt="" />
					</div>
				</div>
				<img className="danceDolphin1" height="350px%" src={dolphin} alt="" />
			</div>
			<div id="mobileContainerHome">
				<img
					id="dd1"
					className="danceDolphin2"
					height="350px"
					src={dolphin}
					alt=""
				/>
				<img
					id="dd2"
					className="danceDolphin2"
					height="350px"
					src={dolphin}
					alt=""
				/>
			</div>
		</div>
	);
}

export default Home;
