import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import iData from "../../../store/IssueData";

function Issue_3() {
	const routes = {
		0: "polkadot",
		1: "sushi",
		2: "tendies",
		3: "chives",
		4: "godfather",
		5: "kulupu",
		6: "music",
	};

	return (
		<Fragment>
			<h1>October 2020 Issue</h1>
			<div className="articleContainer">
				{iData[2].articles.map((article, i) => (
					<Link
						id={`3_` + routes[i]}
						className="articleBox"
						key={i}
						to={`/issues/3/` + routes[i]}
					>
						<div className="listTitle">{article[0]}</div>
						{article[1]}
					</Link>
				))}
			</div>
		</Fragment>
	);
}

export default Issue_3;
