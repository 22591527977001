import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue4/women/1.jpg"
import i2 from "../../../../img/issues/issue4/women/2.jpg"
import i3 from "../../../../img/issues/issue4/women/3.jpg"
import i4 from "../../../../img/issues/issue4/women/4.jpg"
import i5 from "../../../../img/issues/issue4/women/5.jpg"
import i6 from "../../../../img/issues/issue4/women/6.jpg"

function Women_4() {
	return (
		<Fragment>
			<h1 className="articleTitle">Women in Blockchain</h1>
			<div id="h">Inc3LR4ge911</div>
			<br />
			<div id="h">November 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div style={{color:"red", textAlign:"center", fontSize:"1.8rem"}}>*****************************</div>
				<div style={{ textAlign:"center", fontSize:"1.8rem"}}>****DISCLAIMER****</div>
				<div style={{color:"red", textAlign:"center"}}>This article was written by an angry incel, burned by too many rug pulls and sugar baby scams- the volatility of crypto has fried his nerves.</div>
				<div style={{ textAlign:"center", fontSize:"1.8rem"}}>*****************************</div>
				<img src={i1} alt=""/>
				<div><span className="leadLetter">T</span>here is an increasingly common creature out there. A creature so wretched and vile that speaking of it by name is likely to result in a libel lawsuit. A twisted, gnarled thing, filled with silicon, fillers and low self esteem, that exists for only one purpose: to siphon the life energy of the virgin autist. We are of course speaking of the fabled WOMAN IN BLOCKCHAIN.</div>
				<img src={i2} alt=""/>
				<div>One of Satoshi’s jezebels, the Woman in Blockchain (WIB) is a parasitic creature. Normally hailing from destitute parts of Eastern Europe, they are raised from a young age to hunt. Their fathers, father’s friends, and other local men in the community train them early on in the arts of narcissism, sexual abuse, zero boundaries, and above all else, a strong desire for money. Bred to be bred, their self esteem is beaten out of them early on and replaced with the predatory values of their poor local communities. These wretched WIBs are programmed with one objective: hunt and consume resources.</div>
				<div>To do this they must find a food source that is bountiful and plenty, full of meaty creatures with low self esteem that have become too cucked and civilized to resist the temptation of the WIB. Rumor has it that if you say the word “Crypto” three times in the dark in the bathroom mirror an “Anastasia” appears. This is what happened to the modern day Sodom we refer to as San Francisco.</div>
				<img src={i3} alt=""/>
				<div>2017 was a golden age for financial innovation in the city of San Francisco. Crypto had reached an all time high and incel autists finally had enough courage to leave the house for the first time. This also proved to be a golden age for Satoshi's Jezebels, as the WIB now had a golden opportunity to find the richest and lowest self esteem men on the planet to manipulate and entrap. This prompted the largest exodus of Eastern European women to the United States since the breakup of Yugoslavia in 1991. Some say the 2017 WIB hunt permanently altered the demographic information of the world and maps needed to be redrawn. It was a pivotal moment in history.</div>
				<img src={i4} alt=""/>
				<div>Many naive autists were lost in what became known as “The Russian Feast.” The WIBs worked quickly and with alarming coordination. They immediately organized and began infiltrating Crypto meetups in San Francisco and various American cities. Their goal was to show mild interest in a nerd’s topic so that he kept speaking. The more he spoke, the more buy in he had, and the more the WIB had leverage over its prey. For many autists, this was their first time speaking to a woman, let alone an Eastern European with fillers, and they were completely outmatched. The WIBs coordinated through viral social media campaigns launching hashtag whore movements such as: “Women in Blockchain”, “Satoshi Is Female”, and “Peace for Paris.”</div>
				<div>In the rare occasion that an autist that may have had sex at one point in his life caught wind of their entrapment campaign, he would be systematically destroyed by WIBs playing the “ME TOO” card and “CANCELLING” their opponent. With the fury of 10,000 screeching banshees they would peck the threat into total submission with disinformation campaigns that would make Mossad jealous.</div>
				<img src={i5} alt=""/>
				<div>All things must come to an end, and once the crypto bubble popped in 2018, the WIBs' jobs were done. The top WIBs had succeeded in their goals: they had entrapped a naive crypto rich husband, and the most successful of WIBs had even gotten pregnant. They had made it. The lowest of WIBs failed in their missions, being ultimately used by every low status man in San Francisco only to return home, head and genitals sagging in shame, likely to be burned at the stake by their fathers.</div>
				<img src={i6} alt=""/>
				<div>The WIB is a violent wretched creature that is becoming increasingly common. The only way to defend yourself against these parasitic vultures is to practice building self-esteem early on, or when meeting a WIB face to face, utter the prayer incantation that repels them away: “So what do you do?” This question short wires their logic as they realize they don’t do anything. It is in this pause that you realize you’re dealing with a WIB and to escape the situation. The more advanced ones have pre-canned answers such as: “Community Manager” or “Social Media Manager” for a Shitcoin company.</div>
				<div style={{fontSize:"1.8rem", textAlign:"center"}}>DO NOT BE FOOLED. IT IS NOT REAL.</div>
				<div>We wish you all luck out there to protect yourself against the vile, horrible, plastic creature known as the WOMAN IN BLOCKCHAIN.</div>
			</div>
		</Fragment>
	);
}

export default Women_4;
