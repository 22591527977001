import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue4/intervention/1.jpg"
import i2 from "../../../../img/issues/issue4/intervention/2.jpg"
import i3 from "../../../../img/issues/issue4/intervention/3.jpg"
import i4 from "../../../../img/issues/issue4/intervention/4.jpg"
import i5 from "../../../../img/issues/issue4/intervention/5.jpg"
import i6 from "../../../../img/issues/issue4/intervention/6.jpg"

function Intervention_4() {
	return (
		<Fragment>
			<h1 className="articleTitle">Shitcoin Crisis Intervention</h1>
			<div id="h"></div>
			<br />
			<div id="h">November 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
                <div> <span className="leadLetter">A</span>re you a shitcoin founder in crisis? </div>
                <div>Did your launch blow up disastrously? </div>
                <div>Did you pull the rug (without even meaning to)? </div>
                <div>Are bugs, hacks and complex arb exploits getting you down?</div>
                <div>More death threats than you can count?</div>
                <div>Is the shitcoin reality hitting you all at once?</div>
                <div>Our team of shitcoin crisis intervention specialists might be for you. </div>
                <img src={i1} alt="" />
                <div style={{color:"lightsteelblue", textAlign:"center", fontSize:"1.8rem"}}>Meet Our Team of Specialists</div>
                <img src={i2} alt="" />
                <div style={{textAlign:"center", fontSize:"1.8rem"}}>Admiral Charles Flip</div>
                <div>With 30 years of Naval experience and 3.5 years of shitcoin experience, Admiral Flip will get your shitcoin back into tip top shape. As the sailors say, the buck stops with Flip. </div>
                <div>Specialties include:</div>
                <div> • Ending the death threats TODAY</div>
                <div> • Tokenomics redesign (aka pumpamentals revamp)</div>
                <div> • Emergency M&A, vampire attack and hostile takeover support</div>
                <div> • Shitcoin bootcamp training for the whole team (with psychedelics) </div>
                <div> • Founder therapy/executive coaching/chakra realignment</div>
                <div> • Launch narrative formation and "ring leading"</div>
                <div> • PR strategies during crisis situations</div>
                <div> • Community intervention</div>
                <div> • Flip the "situation"</div>
                <img src={i3} alt=""/>
                <img src={i4} alt=""/>
                <div style={{textAlign:"center", fontSize:"1.8rem"}}>Frank "the fixer" Flipper</div>
                <div>With years of experience in "special shituations", there isn't a problem too obscure for Frank to solve. Have funds stuck in a bank in an obscure SE Asian country? Are FUDsters causing trouble in your chat groups? Has the community turned against you? Do you need the immediate tokenization of obscure assets? Do you need special nootropics hand delivered to you in a foreign land? No problem, big or small, isn't solved by Frank's call.</div>
                <div> • Abstract tokenization strategies</div>
                <div> • Logistical support on the ground</div>
                <div> • Obscure translations of economic esoteria</div>
                <div> • Community morale support</div>
                <div> • Makes problems "go away"</div>
                <div> • Memetic warfare and lobbying campaigns</div>
                <div> • Blackmail issues (negotiations with hackers and sim swappers)</div>
                <div> • Employee and sugar baby terminations</div>
                <div> • KYC/AML from a whole Russian village</div>
                <div> • Double sonar communication</div>
                <img src={i5} alt="" />
                <br/>
                <div style={{textAlign:"center", fontSize:"1.8rem", color:"purple"}}>Let dolphin consciousness work for you today!</div>
                <div style={{textAlign:"center"}}>Let our crack team of cetacean shitcoin specialists save your shitcoin. </div>
                <br/>
                <img src={i6} alt=""/>

                
			</div>
		</Fragment>
	);
}

export default Intervention_4;
