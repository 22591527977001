import React from "react";
import "../../css/components/pages/Praxis.scss";
import dolphingirl from "../../img/dolphingirl.webp";
import heart from "../../img/heart.png";
import p1 from "../../img/praxis/p1.jpg";
import p2 from "../../img/praxis/p2.jpg";
// import p3 from "../../img/praxis/p3.jpg";
import p4 from "../../img/praxis/p4.jpg";
import p5 from "../../img/praxis/p5.jpg";
import p6 from "../../img/praxis/p6.jpg";
import p7 from "../../img/praxis/p7.jpg";
import p8 from "../../img/praxis/p8.jpg";
import p9 from "../../img/praxis/p9.jpg";
import p10 from "../../img/praxis/p10.jpg";
import p11 from "../../img/praxis/p11.jpg";
import p12 from "../../img/praxis/p12.jpg";
import p13 from "../../img/praxis/p13.jpg";

function Praxis() {
	return (
		<div className="articleTextContainer">
			<h1 style={{ textAlign: "center", alignSelf: "center" }}>
				PRAXIS
			</h1>
			<br />
			{/* <img height="25px" src={heart} alt="" /> */}
			<br />
			<br />
			<img src={p1} alt="" />
			<img src={p2} alt="" />
			{/* <img src={p3} alt="" /> */}
			<div
				style={{
					color: "red",
					fontSize: "1.8rem",
					fontStyle: "italic",
					textAlign: "center",
					fontWeight: 5000,
					// zIndex: 5,
					padding: "15px 0 15px 0",
				}}
			>
				Harvest "hacker" gets away with $24mm in a complex arbitrage "exploit"
				and only returns 10% to the deployer, not 50% as per industry standard.
			</div>
			<img src={p4} alt="" />
			<img src={p5} alt="" />
			<div
				style={{
					color: "red",
					fontSize: "1.8rem",
					fontStyle: "italic",
					textAlign: "center",
					fontWeight: 5000,
					// zIndex: 5,
					padding: "15px 0 15px 0",
				}}
			>
				Michael Saylor, HNS Bull Bull Bull?
			</div>

			<img src={p6} alt="" />
			<img src={p7} alt="" />
			<img src={p8} alt="" />
			<img src={p9} alt="" />
			<img src={p10} alt="" />
			<img src={p11} alt="" />
			<img src={p12} alt="" />
			<img src={p13} alt="" />
			{/* <h1>
				T<span id="h">h</span>E ART OF T<span id="h">h</span>E S
				<span id="h">h</span>ILL
			</h1>
			<br />
			<img height="25px" src={heart} alt="" />

			<br />
			<br />
			<br />
			<p>we are note paid shills.</p>
			<p>we are bag holders.</p>
			<p>because of this,</p>
			<p>we have a beat on the coin.</p>
			<p>we shill with authenticity.</p>
			<h2>
				S<span id="h">h</span>ILLING IS AN ART
			</h2>
			<br />
			<div className="praxisLine"></div>
			<br />
			<p>we are voices in the machine.</p>
			<p>coins are minted from</p>
			<p>algorithms and code.</p>
			<p>the coin's material</p>
			<p>embeds into its community's mindshare.</p>
			<p>price is sentiment.</p>
			<p>stakeholder becomes pool member.</p>
			<h2>
				S<span id="h">h</span>ILLING IS CYBERNETIC
			</h2>
			<br />
			<div className="praxisLine"></div>

			<br />
			<p>we shill to actualize a coin</p>
			<p>by bringing in based liquidity</p>
			<p>through our dialogue</p>
			<p>kissed aloft so by that</p>
			<p>dank muse named meme.</p>
			<p>her postmodern collision</p>
			<p>of the culture of</p>
			<p>hyper-capitalism.</p>
			<p>we are humorous because we know how the game works.</p>
			<h2>
				S<span id="h">h</span>ILLING IS S<span id="h">h</span>ARING
			</h2>
			<h2>
				T<span id="h">h</span>E LOVE FOR A COIN
			</h2>
			<br />
			<br />
			<img id="dolphinGirl" src={dolphingirl} alt="" />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br /> */}
		</div>
	);
}

export default Praxis;
