import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import issues from "../../store/IssueData";
import "../../css/components/pages/Issues.scss";
import brain from "../../img/brain.png";
import i1 from "../../img/issues/issue1/1 cover.gif";
import i2 from "../../img/issues/issue2/2 cover.png";
import i3 from "../../img/issues/issue3/3 cover.webp";
import i4 from "../../img/issues/issue4/4 cover.jpg"

function Issues() {
	return (
		<Fragment>
			<h1>WONDER BEGETS WISDOM</h1>
			{/* <br />
			<img height="25px" src={brain} alt="" />

			<br />
			<br />
			<br /> */}
			<div id="issuesContainer">
				<Link className="issueBox" id="issue4" to="/issues/4">
					<img src={i4} alt=""></img>
					<div>
						{" "}
						<div>Issue #4</div>
						<div>November 2020</div>
					</div>
				</Link>
				<Link className="issueBox" id="issue3" to="/issues/3">
					<img src={i3} alt=""></img>
					<div>
						{" "}
						<div>Issue #3</div>
						<div>October 2020</div>
					</div>
				</Link>
				<Link className="issueBox" id="issue2" to="/issues/2">
					<img src={i2} alt=""></img>
					<div>
						<div>Issue #2</div>
						<div>August 2020</div>
					</div>
				</Link>
				<Link className="issueBox" id="issue1" to="/issues/1">
					<img src={i1} alt=""></img>
					<div>
						{" "}
						<div>Issue #1</div>
						<div>July 2020</div>
					</div>
				</Link>
			</div>
		</Fragment>
	);
}

export default Issues;
