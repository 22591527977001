import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue2/nft/1.webp";
import i2 from "../../../../img/issues/issue2/nft/2.webp";
import i3 from "../../../../img/issues/issue2/nft/3.webp";
import i4 from "../../../../img/issues/issue2/nft/4.webp";
import i5 from "../../../../img/issues/issue2/nft/5.webp";
import i6 from "../../../../img/issues/issue2/nft/6.jpeg";
import i7 from "../../../../img/issues/issue2/nft/7.jpeg";

function NFT_2() {
	return (
		<Fragment>
			<h1 className="articleTitle">Where do NFTs value sink to?</h1>
			<div id="h">Tamara Frankel</div>
			<br />
			<div id="h">August 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div
					style={{
						marginRight: "auto",
						marginLeft: "auto",
						textAlign: "center",
						maxWidth: "400px",
					}}
				>
					When will we finally get an NFT Museum to donate our digital artworks
					to for the tax benefits to offset our DeFi gains?
				</div>
				<img src={i1} alt="" />
				<div>
					<span className="leadLetter">W</span>hile the NFT market and platforms
					such as SuperRare and Rari are heating up - some even say NFTs are the
					next big thing after DeFi - one must ask the question, where does the
					value sink to?
				</div>
				<div>
					While pumping an NFT is similar to pumping a shitcoin - fill your bags
					first with early works then{" "}
					<span
						style={{
							font: "inherit",
							background: "#D83D3D",
							fontStyle: "italic",
						}}
					>
						shill, shill, shill
					</span>{" "}
					- one has to wonder are these just a non-fungible iteration on meme
					coins or is there real value there? How can we restructure the meme
					market to resemble the value capture of the art world?
				</div>
				<img src={i3} alt="" />
				<div>Let's take a look at how the traditional art world works.</div>
				<div>
					Art works are typically sold from a gallery, of which the artist only
					keeps 50% (or less) of the sale price. At that point, the owner of the
					art work can hold the art work for a period of time then donate it to
					a museum or other non-profit institute if they are so inclined. The
					museum (where the owner of the art work probably sits on the board)
					then sets the value of the artwork and the owner/donor gets to use 50%
					of the donated value as a tax write off - the deduction is on the
					sales/donation price, not on the acquisition price.
				</div>
				<div>
					For example, let's say a collector pays $1mm for an artwork, then the
					museum he donates the art work to (where he sits on the board) decides
					it is worth $10mm. Effectively he has realized $5mm in tax write offs.{" "}
					<span
						style={{
							font: "inherit",
							background: "#D83D3D",
							fontStyle: "italic",
						}}
					>
						Buy, donate, profit.
					</span>
				</div>
				<img src={i4} alt="" />
				<div>
					How can we have this type of incentive structure in the NFT space?
				</div>
				<div>
					Crypto holders notoriously have tax issues and are in dire need of tax
					write-off strategies for their outsized gains.
				</div>
				<div
					style={{
						fontSize: "1.8rem",
						textAlign: "center",
						color: "red",
						fontStyle: "italic",
					}}
				>
					When will we finally get an NFT Museum to donate our digital artworks
					to for the tax benefits to offset our DeFi gains?
				</div>
				<img src={i5} alt="" />
				<div style={{ textAlign: "center" }}>memes by Brad Troumel</div>
				<div style={{ textAlign: "center" }}>Have an NFT!</div>
				<img src={i6} alt="" />
				<a
					className="raribleButtonArticle"
					style={{ alignSelf: "center", zIndex: "5" }}
					href="https://app.rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:2048:0xa577edbd1413f070733602d2baf78a4861c5d8d0"
				>
					{`RARIBLE`}
				</a>
				<img src={i7} alt="" />
				<a
					className="raribleButtonArticle"
					style={{ alignSelf: "center", zIndex: "5" }}
					href="https://app.rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:1937

"
				>
					{`RARIBLE`}
				</a>
			</div>
		</Fragment>
	);
}

export default NFT_2;
