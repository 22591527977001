import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue3/godfather/1.jpg";
import i2 from "../../../../img/issues/issue3/godfather/2.jpg";
import i3 from "../../../../img/issues/issue3/godfather/3.jpg";

function Godfather_3() {
	return (
		<Fragment>
			<h1 className="articleTitle">The Godfather of Shakes</h1>
			<div id="h">/hns_god</div>
			<br />
			<div id="h">October 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<img src={i1} alt="" />
				<div>
					<span className="leadLetter">I</span>n hushed tones and soft whispers,
					they call him the Godfather of Shakes or 摇摇欲坠的上帝. Not a single
					HNS token changes hands over the counter without the godfather and his
					ruthless shakes cartel taking their tax. They control the HNS supply
					and tax anyone trying to acquire or sell this precious commodity off
					of a centralized exchange. Their spies are everywhere. Any surplus
					value in the ecosystem will be extracted by the shakes cartel, while
					the Godfather shills mercilessly, giving a pitiful amount of coins as
					airdops to the fellow hard working shills. In the streets they
					whisper, "just market buy, don't let Godfather Shakes find out, may
					the Hotbit gods protect you". ​
				</div>
				<div>
					​ You might ask, dear reader, how did the godfather come to power in a
					land with no kings, a PoW blockchain aiming to become a namespace for
					the decentralized world? Well, in a land with no kings, power is there
					for the taking, and the Godfather has the will to power.
				</div>
				<img src={i2} alt="" />
				<img src={i3} alt="" />
			</div>
		</Fragment>
	);
}

export default Godfather_3;
