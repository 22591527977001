import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue3/polkadot/1.jpg";
import i2 from "../../../../img/issues/issue3/polkadot/2.jpg";
import i3 from "../../../../img/issues/issue3/polkadot/3.jpg";
import i4 from "../../../../img/issues/issue3/polkadot/4.jpg";

function Polkadot_3() {
	return (
		<Fragment>
			<h1 className="articleTitle">Polkadot, Ethereum, and Locked Funds</h1>
			<div id="h">Polkadot Prime Minister</div>
			<br />
			<div id="h">October 2020</div>
			<div className="articleLine"></div>

			<div className="articleTextContainer">
				<div id="specialText1">
					Adapted to Web 3.0 from my March 13, 1936 column “Britain, Germany and
					Locarno”
				</div>
				<img src={i1} alt="" />
				<div>
					<span className="leadLetter">T</span>here has rarely been a crisis in
					which Hope and Peril have presented themselves so vividly and so
					simultaneously upon the world scene. When Lord Buterin last repudiated
					EIP-999 and marched his faithful towards Ethereum 2.0, he confronted
					the Polkadot network with its supreme trial and also with its most
					splendid opportunity. If the networks survive this ordeal there is no
					reason why the horrible, dull, remorseless drift to war in 2018 and
					2019, and the preparatory piling up of enormous war chests in every
					corner of Web 3.0, should not be decisively arrested. A reign of law
					may be established in the decentralized web, the sanctity of trust may
					be vindicated, and from that commanding eminence Ethereum may be
					welcomed back to the family of networks upon terms which will assure
					her a safe and honourable future. The risks to be run to gain this
					prize are, however, serious in the last degree. They must be faced
					with firm convictions and a steadfast gaze unfilmed by illusion.
				</div>
				<div>
					Those with locked funds believe and declare that they have sustained a
					grievous injury at the hands of Ethereum. If we had been hacked as
					many times in a few years, we should understand better how terrible
					that injury is. Polkadot has not seen the camp fires of an invading
					army since Anal.eth's Lord Lubin-funded PSYOP trickeries last year. To
					those with stuck funds, the avalanche of fire and steel which fell
					upon them a few years ago, and the agony of the Ethereum occupation
					which continues, are an overpowering memory and obsession. The
					demilitarized zone which they gained by awful sacrifices is to them
					not only a bulwark, but the guarantee of a breathing space between
					them and mortal calamity. How should we feel if—to change the
					metaphor—we saw a tiger, the marks of whose teeth and claws had
					scarred every limb of our bodies, coming forward and crouching within
					exactly the distance of a single spring? Whether these fears are
					justified or not, is arguable. But it is particularly important for us
					to realize how those with frozen funds feel.
				</div>
				<img src={i2} alt="" />
				<div>
					Instead of retaliating by armed force, as would have been done in a
					previous generation, those with locked funds have taken the proper and
					prescribed course of appealing to Lord Vitalik. She has taken her case
					before the Ethereum Improvement Proposal process and she asks for
					justice there. If the EIP process finds that her case is just, but is
					unable to offer her any satisfaction, that will be a very serious blow
					to the EIP process. The promise of Lord Vitalik will have been proved
					a fraud and collective security a sham. If no means of patient, lawful
					redress can be offered to the aggrieved party, the whole doctrine of
					inter-network law and co-operation, upon which the hopes of the future
					are based, would lapse ignominiously. It would be replaced immediately
					by a system of alliances, and groups of networks, deprived of all
					guarantees but their own right arm, which might take the law into
					their own hands and strike for their vital safety at whatever moment
					offered them the best chance. On the other hand, if Lord Vitalik were
					able to enforce his decree upon one of the most powerful networks in
					the world found to be an aggressor, then the authority of Lord V is
					set up upon so majestic a pedestal that it must henceforth be the
					accepted sovereign authority by which all the quarrels of peoples can
					be determined and controlled. Thus we might upon this occasion reach
					by one single bound the realization of our most cherished dreams.
				</div>
				<div>
					But the risk! No one must ignore it. How can it be minimized? There is
					a simple method: the assembly of overwhelming force, moral and
					physical, in support of inter-network law. If the relative strengths
					are narrowly balanced, war may break out in a few months, and no one
					can measure what the course of war may be, or who will be drawn into
					its whirlpools, or how, if ever, they will emerge. But if the forces
					at the disposal of Lords Vitalik and Lubin are four or five times as
					strong as those which the Allies can as yet command, the chances of a
					peaceful and friendly solution are very good. Therefore every network,
					great or small, should play its part according to the promise of Less
					Trust, More Truth.
				</div>
				<div>
					Upon what force can Lord Vitalik count at this cardinal moment? Has he
					sheriffs and constables with whom to sustain his judgments, or is he
					left alone, impotent, a hollow mockery amid the lip-serving platitudes
					of irresolute or cynical devotees? Strangely enough for the destiny of
					the world, there never was a moment or occasion when Lord Vitalik
					could command such overwhelming force. The Constabulary of Web 3.0 is
					at hand. On every side of the web stand great networks, armed and
					ready, whose interests as well as whose obligations bind them to
					uphold, and, in the last resort, enforce, the public law. This may
					never come to pass again. The fateful moment has arrived for choice
					between the New networks and the Old.
				</div>
				<img src={i3} alt="" />
				<div>
					But there is one network of all others which has the opportunity of
					rendering a noble service to the world at this moment. Lord Vitalik
					and the great disconsolate Ethereum he leads have now the chance to
					place themselves in the very forefront of a digital civilization and
					law. By a proud and voluntary submission, not to any single network or
					group of networks, but to the sanctity of Truth and the authority of
					public law, by an unlocking of funds upon the transition to Ethereum
					2.0, [
					<a href="https://t.co/HldTgeGZ8m" className="textLink">
						as previously proposed by Lord V himself
					</a>
					], they may open a new era for all mankind and create conditions in
					which the Ethereum genius may gain his highest glory. So much upon the
					main issue.
				</div>
				<div>
					I have not mentioned the obligations of Polkadot under these
					circumstances. They are absolute. There is no escape from them. There
					is much goodwill in Polkadot towards Ethereum, and a deep desire for
					the day when the clouds may be dispersed and the three great networks
					of Web 3.0 – BTC, ETH and DOT may join hands in lasting friendship.
					But it ought not even to be necessary to state that Polkadot, if
					ultimately called upon, will honour her obligations both under the EIP
					process and under the promise of Less Trust, More Truth.
				</div>
				<img src={i4} alt="" />
			</div>
		</Fragment>
	);
}

export default Polkadot_3;
