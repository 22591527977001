import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import iData from "../../../store/IssueData";

function Issue_2() {
	const routes = {
		0: "waifus",
		1: "slave",
		2: "decentralization",
		3: "nft",
		4: "memes",
	};

	return (
		<Fragment>
			<h1>August 2020 Issue</h1>
			<div className="articleContainer">
				{iData[1].articles.map((article, i) => (
					<Link
						id={`2_` + routes[i]}
						className="articleBox"
						key={i}
						to={`/issues/2/` + routes[i]}
					>
						<div className="listTitle">{article[0]}</div>
						{article[1]}
					</Link>
				))}
			</div>{" "}
		</Fragment>
	);
}

export default Issue_2;
