import React, { Fragment } from "react";
import fire from "../../img/fire.png";

function Submit() {
	return (
		<Fragment>
			<h1>
				GOT A <span id="h">h</span>OT TAKE?
			</h1>
			<br/>
			<br/>
			<div style={{fontSize:"1.8rem"}}>Send submissions to</div>
			<br/>
			<br/>
			<div style={{fontSize:"1.8rem"}}>ponzi@shitcoinpraxis.com</div>
			{/* <br />
			<img height="25px" src={fire} alt="" />

			<br />
			<br />
			<br /> */}
		</Fragment>
	);
}

export default Submit;
