import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue1/szabo/1.webp";
import i2 from "../../../../img/issues/issue1/szabo/2.webp";

function Szabo_1() {
	return (
		<Fragment>
			<h1 className="articleTitle">Szabo Canceled!</h1>
			<div id="h">and, the discrete pleasure of cancel-baiting</div>
			<br />
			<div id="h">July 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<img src={i1} alt="" />
				<div>
					<span className="leadLetter">W</span>as Nick Szabo just trolling
					everyone? Did he keep continually retweeting "offensive" and "racist"
					tweets after so much public backlash so he would get canceled?
				</div>
				<div style={{ textAlign: "center" }}>
					Rid your feed of ETH SJWs with just a few simple steps?
				</div>
				<div style={{ textAlign: "center" }}>
					Is cancel-baiting the ultimate modern pleasure?
				</div>
				<div style={{ textAlign: "center" }}>A post internet cleansing?</div>
				<div style={{ textAlign: "center" }}>A limit experience?</div>
				<div>
					Getting canceled is like getting chewed up and spit out by modernity
					to then be healed by nature after a hard abutment into the meatspace
					to deal with your "problematic" and often times misunderstood
					personality. Due to the almost unquantifiable loss of social capital
					from a cancelation event, self induced cancelations are one of the
					most costly and outlandish modern luxuries for the post-internet age.
					Ten years ago people would go on an ayahuasca retreat- today, they
					just get canceled.
				</div>
				<img src={i2} alt="" />
				<div style={{ textAlign: "center" }}>
					Reading Nick Szabo in Cambodia with a Negroni - don't cancel me
					please!
				</div>
			</div>
		</Fragment>
	);
}

export default Szabo_1;
