import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue1/femdom/1.webp";
import i2 from "../../../../img/issues/issue1/femdom/2.webp";
import i3 from "../../../../img/issues/issue1/femdom/3.webp";
import i4 from "../../../../img/issues/issue1/femdom/4.webp";
import i5 from "../../../../img/issues/issue1/femdom/5.webp";
import i6 from "../../../../img/issues/issue1/femdom/6.webp";
import i7 from "../../../../img/issues/issue1/femdom/7.webp";
import i8 from "../../../../img/issues/issue1/femdom/8.webp";

function Femdom_1() {
	return (
		<Fragment>
			<h1 className="articleTitle">FemDom Farm</h1>
			<div id="h">{null}</div>
			<br />
			<div id="h">July 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "#e663eb",
						fontStyle: "italic",
						margin: "25px auto 25px auto",
					}}
				>
					Who is at the top of the femdom pyramid?
				</div>
				<img src={i1} alt="" />
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "#e663eb",
						fontStyle: "italic",
						margin: "25px auto 25px auto",
					}}
				>
					ETH boys and DeFi degens femdom'd into farming yield by ETH memes and
					COMP fomo.
				</div>
				<img src={i2} alt="" />
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "#e663eb",
						fontStyle: "italic",
						margin: "25px auto 25px auto",
					}}
				>
					ETH network femdom'd by DeFi and yield farmers.
				</div>
				<img src={i3} alt="" />
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "#e663eb",
						fontStyle: "italic",
						margin: "25px auto 25px auto",
					}}
				>
					Maker femdom'd by Compound.
				</div>
				<img src={i4} alt="" />
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "#e663eb",
						fontStyle: "italic",
						margin: "25px auto 25px auto",
					}}
				>
					ETH grabs back!
				</div>
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "#e663eb",
						fontStyle: "italic",
						margin: "25px auto 25px auto",
					}}
				>
					Farmers femdom'd by ETH gas fees.
				</div>
				<img src={i5} alt="" />
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "#e663eb",
						fontStyle: "italic",
						margin: "25px auto 25px auto",
					}}
				>
					Another day on the Yield Farm.
				</div>
				<img src={i6} alt="" />
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "#e663eb",
						fontStyle: "italic",
						margin: "25px auto 25px auto",
					}}
				>
					Don't you want to come simp COMP's BBC too?
				</div>
				<img src={i7} alt="" />
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "#e663eb",
						fontStyle: "italic",
						margin: "25px auto 25px auto",
					}}
				>
					What are you farming for?
				</div>
				<img src={i8} alt="" />
			</div>
		</Fragment>
	);
}

export default Femdom_1;
