import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue1/polkaheads/1.webp";
import i2 from "../../../../img/issues/issue1/polkaheads/2.webp";
import i3 from "../../../../img/issues/issue1/polkaheads/3.webp";
import i4 from "../../../../img/issues/issue1/polkaheads/4.webp";
import i5 from "../../../../img/issues/issue1/polkaheads/5.webp";

function Polkaheads_1() {
	return (
		<Fragment>
			<h1 className="articleTitle">Yield Farming for Polkaheads</h1>
			<div id="h">Tamara Frankel</div>
			<br />
			<div id="h">July 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div
					style={{
						fontSize: "1.8rem",
						textAlign: "center",
						color: "#69f542",
						maxWidth: "350px",
						margin: "0 auto 10px auto",
					}}
				>
					So you Polkaheads want to know wtf is going on in ETH boys' world?
				</div>
				<img src={i1} alt="" />
				<div
					style={{
						fontSize: "1.8rem",
						textAlign: "center",
						color: "#69f542",
						maxWidth: "350px",
						margin: "0 auto 0 auto",
					}}
				>
					What is Farming?
				</div>
				<div
					style={{
						fontSize: "1.8rem",
						textAlign: "center",
						color: "#69f542",
						maxWidth: "350px",
						margin: "0 auto 0 auto",
					}}
				>
					Is it a scam?
				</div>
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "#69f542",
						maxWidth: "350px",
						margin: "25px auto 0 auto",
					}}
				>
					REN CURVE BAL COMP AMPL SOL mStable - WTF are you ETH Heads talking
					about!?!?
				</div>
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "#69f542",
						maxWidth: "350px",
						margin: "25px auto 25px auto",
					}}
				>
					$50/day in gas?!?! Are you ETH Heads crazy? MAYBE...
				</div>
				<div>
					<span className="leadLetter">I</span>t all started when Synthetix{" "}
					<a
						className="textLink"
						href="https://twitter.com/kaiynne/status/1276417760222797825?s=20"
					>
						implemented an SNX reward for LPs providing liquidity to sETH/ETH
						pool on Uniswap
					</a>{" "}
					last year. Synthetix is a DeFi protocol on ETH that lets users issue
					synthetic digital assets (e.g., mint sETH to short real ETH) and, in
					the future, synthetic meme stonks, backed by SNX tokens at a high
					collateralization ratio. The term rose to popularity and became
					popular in DeFi culture with Degen Spartan's, a popular defi commenter
					on twitter, tweets about farming his SNX yield. Degen Spartan actually
					came up with the idea to reimplement this discarded incentive plan for
					the sUSD/ETH Uniswap pool to the sETH/ETH pool and Bojan Kiculovic
					created the pool. This also changed the paradigm of the
					project/investor/user dialogue with the users and investors having
					direct impact on the direction and liquidity bootstrapping incentives
					of a project.
				</div>
				<img src={i2} alt="" />
				<div>
					Even then, farming yield -{" "}
					<span
						style={{
							fontStyle: "inherit",
							background: "red",
							fontStyle: "italic",
						}}
					>
						digital agriculture
					</span>{" "}
					- was still a niche term within the deep early DeFi community until
					Compound began offering it's COMP rewards token as a rebate program to
					borrowers on it's platform. When COMP launched, the COMP ("cash back")
					rewards were worth more than the cost to borrow, so many people were
					incentivized to borrow from Compound to farm COMP and to set up COMP
					Farms. The increased demand for loans increases how much lenders can
					make lending their capital.
				</div>
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "pink",

						margin: "25px auto 0 auto",
					}}
				>
					Gaming the System to Fix It
				</div>
				<div
					style={{
						fontSize: "1.2rem",
						textAlign: "center",
						color: "orange",

						margin: "25px auto 0 auto",
					}}
				>
					"Hello Plebs, I print tokens out of thin air"
				</div>
				<img src={i3} alt="" />
				<div>
					If none of you Polkaheads understood this meme when I posted it in
					Polkadot Trollbox, earlier this week, Sam Bankman-Fried, the genius
					trader and founder of Alameda Research and FTX exchange, found a way
					to game BAL distribution to get awarded more than 50% of last week's
					rewards. Balancer uses Coin Gecko to calculate liquidity in pools; big
					players like FTX can create erc20 assets that appear in CG and set the
					price. FTX used nearly $100mm of derivitives tokens $USDTHEDGE and
					$USDTBEAR that they control and set the price of to reap BAL rewards.
					What followed was a "messy governance by discord channel" where one
					BAL whale rage quit and market dumped his BAL position in protest and
					Sam even showed up for the discussion. In some ways, we need these
					types of exploits to point out the weak points of these new
					experimental systems. What is especially clear is that a good
					governance solution is needed for the these types of farming rewards
					and DeFi sub-networks (on chain governance anyone?). It will be
					interesting to see how Polkadot's on chain governance improves these
					governance decisions.
				</div>
				<img src={i4} alt="" />
				<div
					style={{
						fontSize: "1.4rem",
						textAlign: "center",
						color: "pink",

						margin: "25px auto 25px auto",
					}}
				>
					Will there be farming in the Polkadot ecosystem?
				</div>
				<div>
					Since Polkadot is Proof of Stake unlike ETH 1.x, there will already be
					a push to lock up capital for DOTs staking rewards. But, farming for
					shitcoins might also be a thing but it may look a bit different in the
					Polkadot ecosystem. First off, one must lock up Dots for a parachain -
					I can foresee a future where Dots holders agree to lock up their Dots
					for a parachain in exchange for interest payments in the parachain's
					native tokens - farming for parachain tokens using Dots. I think
					farming on Polkadot will be similar to the Edgeware lockdrop, where
					people locked up their ETH for a certain amount of time to receive EDG
					tokens, much the same way that Thorchain RUNE rewards you for locking
					your money in the RUNE vault and not spending it, except with Dots
					being locked up. Every ecosystem can be farmed, and on a farm,
					everyone works. Every token sitting around not doing anything should
					be put to work!
				</div>
				<img src={i5} alt="" />
			</div>
		</Fragment>
	);
}

export default Polkaheads_1;
