import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue1/yield/1.webp";
import i2 from "../../../../img/issues/issue1/yield/2.webp";
import i3 from "../../../../img/issues/issue1/yield/3.webp";
import i4 from "../../../../img/issues/issue1/yield/4.webp";

function Yield_1() {
	return (
		<Fragment>
			<h1 className="articleTitle">The Meme of Yield Farming</h1>
			<div id="h">DegenSpartan</div>
			<br />
			<div id="h">July 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div></div>
				<div>
					<span className="leadLetter">Y</span>ield farming is all the rage
					these days - "plant" some tokens, add a bit of water, kick back relax
					and "harvest" your crops when they are ready.
				</div>
				<div>
					This recent hype of yield farming was kicked off by COMP mainly
					because (1) the yields were mind-blowingly amazing, and (2) you can
					earn those crazy yields even while using a fuckton of capital. Triple
					digit APY using 7-8 figures balances without gettting throttled? Pass
					me the hoe, bitch. IT'S TIME TO FARM.
				</div>
				<img src={i1} alt="" />
				<div>
					But before you get too excited about bringing your hoe out into the
					fields and start ploughing hard, it is worth it to consider, "where
					does all this value come from?"
				</div>
				<div>The value has to come from somewhere.</div>
				<div>
					If you remember shitcoin masternodes from an era long ago, you'd
					remember that you can get 235,908% annual returns just from building
					and maintaining one. You'd send those trash straight to Cryptopia and
					blast down through the order books. Who gave a shit? It's free money
					anyway!
				</div>
				<div>
					Well why was that so? Answer: the value of the masternode's ROI came
					only from inflation. All non-masternode holders were being inflated
					away like crazy to subsidizer those masternodes in the network.{" "}
				</div>
				<img src={i2} alt="" />
				<div>Fast forward now to mid 2020.</div>
				<div>
					The magic innovation of COMP is that the value of it does not come
					from meaningless inflation. It instead actually comes from the
					expectation that thte coins will be worth something in the future.
				</div>
				<div>
					But why didn't masternode coins also have this expectation? Simple.
					Almost all masternode coins were just copy past nonsense with no
					impactful innovation.
				</div>
				<div>
					Compound on the other hand, has a protocol that has had no major
					hacks, invested by prominent funds (a`16z, Paradigm, Polychain, etc)
					is actually used quite widely throughout the DeFi ecosystem and has a
					very straightforward business model - matching lenders and borrowers.
				</div>
				<div>
					These COMP tokens being given out to yield farmers are actually the
					net present value of the potential of what these tokens might be worth
					in the future, and there is an actual product and serious money
					backing that idea. And that is what gives it value. The hope that one
					day future rent extraction will be voted into the tokens and everyone
					can retire rich, being fractional owners of the world's most
					decentralized lending and borrowing platform.
				</div>
				<img src={i3} alt="" />
				<div>
					But I am not here today to shill you COMP. Instead, I have something
					else on my mind...
				</div>
				<div>
					Synthetix (SNX) is actually the OG in yield farming. They launched
					their liquidity incentives mid 2019 and are currently running 6
					different incentive programmes!
				</div>
				<div>
					So you can stake their token (SNX) and mint some of their child token
					(sUSD, a stablecoin) and use that stablecointo partixipate in their
					incentive programmes to earn even more tokens.
				</div>
				<div>
					Hate the idea? Hate the project? This it's stupid? Dump the tokens
					later. It's your perogative. You are getting paid to provuide
					liquidity, what you do with your reward after that is up to you.
				</div>
				<div>
					But look at their latest liquidity incentive. A triple liquidity pool
					using Curve that has renBTC wBTC and sBTC, that will reward yield
					farmers with Curve LP fees, CRV governance tokens, REN incentives, SNX
					incentives, and BAL governance tokens. 5 types of yield packed in 1
					single strategy!
				</div>
				<div>
					Just like the example of COMP, the SNX tokens that yield farmers
					receive are projected to be worth something in the future since it's a
					share of a project, which actually exists, has users, and is
					continually pushing updates. So it is not just mindless inflation.
				</div>
				<div>
					I haven't even started on the actual fundamentals and TAM of the
					project yet, but of course I'm bullish and biased, so probably best
					not to hear that from.
				</div>
				<div>
					My shill today is to check out the different SNX liquidity incentives
					and perhaps pick one that suits your fancy (retains your long
					exposure, easy hedged, etc) and slowly see if you like the experience
					so far. You might be surprised when you find out what the project is
					actually doing and how lose they are to acheiving that.
				</div>
				<div>Have a good day, farmers.</div>
				<img src={i4} alt="" />
			</div>
		</Fragment>
	);
}

export default Yield_1;
