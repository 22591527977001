import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue3/chives/1.jpg";
import i2 from "../../../../img/issues/issue3/chives/2.jpg";
import i3 from "../../../../img/issues/issue3/chives/3.jpg";
import i4 from "../../../../img/issues/issue3/chives/4.jpg";
import i5 from "../../../../img/issues/issue3/chives/5.jpg";
import i6 from "../../../../img/issues/issue3/chives/6.jpg";
import i7 from "../../../../img/issues/issue3/chives/7.jpg";
import i8 from "../../../../img/issues/issue3/chives/8.webp";
import i9 from "../../../../img/issues/issue3/chives/9.webp";
import i10 from "../../../../img/issues/issue3/chives/10.webp";

function Chives_3() {
	return (
		<Fragment>
			<h1 className="articleTitle">A Song For Chives</h1>
			<div id="h">Chilaquilas in the Morning</div>
			<br />
			<div id="h">October 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div id="specialText1">#ACAN #CLM</div>
				<img src={i1} alt="" />
				<img src={i2} alt="" />
				<img src={i3} alt="" />
				<div>
					<span className="leadLetter">CHIVES: </span>they live to be cut and
					multiply endlessly, but we should not take chives for granted- Chives'
					Lives Matter too. If it wasn't for the many layers of chives in our
					market, we wouldn't be able to survive. So, take a moment to say thank
					you to the chives all around the world buying your dumps, always there
					to clean up the mess, and eat endless supplies of shitcoins. All hail
					the humble chive!
				</div>
				<div
					style={{
						color: "red",
						fontSize: "1.8rem",
						fontStyle: "italic",
						textAlign: "center",
						fontWeight: 5000,
						// zIndex: 5,
						padding: "15px 0 15px 0",
					}}
				>
					When you harvest the chives too early, you lose your exit liquidity.
					Few understand but many should.
				</div>
				<img src={i4} alt="" />
				<img src={i5} alt="" />
				<img src={i6} alt="" />

				<div
					style={{
						color: "red",
						fontSize: "1.8rem",
						fontStyle: "italic",
						textAlign: "center",
						fontWeight: 5000,
						// zIndex: 5,
						padding: "15px 0 15px 0",
					}}
				>
					Own a piece of history!
				</div>
				<img src={i7} alt="" />
				<a
					className="raribleButtonArticle"
					style={{ alignSelf: "center", zIndex: "5" }}
					href="https://app.rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:25725:0xf752ae76ed40c4bc8cb49263b3db46749e56c35b"
				>
					{`RARIBLE`}
				</a>
				<img src={i8} alt="" />
				<a
					className="raribleButtonArticle"
					style={{ alignSelf: "center", zIndex: "5" }}
					href="https://app.rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:22738:0xe7293e5a25bbed28843b179c73edfd4d63367e5b"
				>
					{`RARIBLE`}
				</a>
				<img src={i9} alt="" />
				<a
					className="raribleButtonArticle"
					style={{ alignSelf: "center", zIndex: "5" }}
					href="https://app.rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:22622:0x329b90dd19e3ca381e5c05e071c276401d3c03c6"
				>
					{`RARIBLE`}
				</a>
				<img src={i10} alt="" />
				<a
					className="raribleButtonArticle"
					style={{ alignSelf: "center", zIndex: "5" }}
					href="https://app.rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:22798:0xe950c23891e41e5bb3fe4a45dde62752a4bbf9fb"
				>
					{`RARIBLE`}
				</a>
			</div>
		</Fragment>
	);
}

export default Chives_3;
