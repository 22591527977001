const issues = [
	{
		id: 1,
		year: "2020",
		month: "July",
		articles: [
			["Bondage Curve", "Anon"],
			["Szabo Cancelled", "Anon"],
			["TFW You're a Based Degen Trader", "Anon"],
			["Yield Farming for Polkaheads", "Tamara Frankel"],
			["FemDom Farm", "Anon"],
			["The Meme of Yield Farming", "DegenSpartan"],
		],
		url: ["bondage", "szabo", "degen", "polkaheads", "femdom", "yield"],
	},
	{
		id: 2,
		year: "2020",
		month: "August",
		articles: [
			["YFI's Forking Waifus", "WEEB"],
			["Are You a Slave to Your Shitcoin?", "COGDISS"],
			["Decentralization is Fake and Gay", "RealCY199"],
			["Where Do NFT's Value Sink To?", "Tamara Frankel"],
			["Meme Blocks", "Anon"],
		],
		url: ["waifus", "slave", "fake-and-gay", "urbit", "nft", "memes"],
	},
	{
		id: 3,
		year: "2020",
		month: "October",
		articles: [
			["Polkadot, Ethereum, and Locked Funds", "Polkadot Prime Minister"],
			[
				"The Great Sushi Wars",
				"TheRealMasterChef696969696969696969696969696969",
			],
			["Chicken Tenders for the Crypto NEET", "Hardwood_"],
			["A Song for Chives. #CLM", "Anon"],
			["The Godfather of Shakes", "/hns_god"],
			["It's Kulupu Time!", "Anon"],
			["When the Music Stops", "C.B. Dubois"],
		],
		url: [
			"polkadot",
			"sushi",
			"tendies",
			"chives",
			"godfather",
			"kulupu",
			"music",
		],
	},
	{
		id: 4,
		year: "2020",
		month: "November",
		articles: [["The River Eternal", "Jenny Entropy"],["Late Stage 2020","Schizotopia"],["On Rugs","@Hardwood_"],["Dear DeFi Karen","DeFi Karen"],["Shitcoin Crisis Intervention","Anon"],["The Crypto Libidinal Economy","Anon"],["Women in Blockchain","Inc3LR4ge911"],["Female Incelism","CetaceanFutures411"],["A Eugenic Cetacean Manifesto","AtlanteanDreamz"],["Omnilingual Clairaudience","Amanda Devik"]],
		url: ["river","latestage","rugs","karen","intervention","libidinal","women","incelism","eugenic","clairaudience"],
	},
];

export default issues;
