import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue4/river/1.jpg";

function River_4() {
	return (
		<Fragment>
			<h1 className="articleTitle">The River Eternal</h1>
			<div id="h">Jenny Entropy</div>
			<br />
			<div id="h">November 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div style={{ textAlign: "center", maxWidth: "800px" }}>
					<span className="leadLetter">T</span>ime is like a river. You can not
					touch the same water twice, because the flow that has passed will
					never pass again.
				</div>
				<div
					style={{
						textAlign: "center",
						fontSize: "1.8rem",
						color: "yellow",
					}}
				>
					Purchase
				</div>
				<a
					className="nftButton"
						id="nftButtonId"
					style={{ alignSelf: "center", zIndex: "5", transform:"none" }}
					href="https://app.rarible.com/token/0xd07dc4262bcdbf85190c01c996b4c06a461d2430:82611:0x644e1cc75902f5e6d88910ea2412276141dea014"
				>
					{`RARIBLE`}
				</a>
				<div
					style={{
						textAlign: "center",
						fontSize: "1.8rem",
						color: "rgb(0, 128, 0)",
					}}
				>
					SCROLL >>
				</div>
			</div>
			<div
				id="hzScroll"
				style={{
					display: "inline-block",
					overflowX: "scroll",
					overflowY: "hidden",
					whiteSpace: "nowrap",
					// height: "95vh",
					// maxHeight: "95vh",
					width: "100vw",
					paddingBottom: "15px",

					// left: "0",
					// padding: "15px",
					// border: "5px red solid",
				}}
			>
				{" "}
				<img
					style={{
						top: "0",
						display: "inline-block",
						objectFit: "cover",
						maxHeight: "95vh",
						// width: "200vw !important",
						height: "auto",
						margin: "0 20px 0 20px",
						padding: "none",
						// overflow: "visible",
					}}
					src={i1}
					alt=""
				/>
			</div>
		</Fragment>
	);
}

export default River_4;
