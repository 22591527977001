import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import iData from "../../../store/IssueData";

function Issue_4() {
	const routes = {
		0: "river",
		1: "latestage",
		2: "rugs",
		3: "karen",
		4: "intervention",
		5: "libidinal",
		6: "women",
		7: "incelism",
		8: "eugenic",
		9: "clairaudience"
	};

	return (
		<Fragment>
			<h1>November 2020 Issue</h1>
			<div className="articleContainer">
				{iData[3].articles.map((article, i) => (
					<Link
						id={`4_` + routes[i]}
						className="articleBox"
						key={i}
						to={`/issues/4/` + routes[i]}
					>
						<div className="listTitle">{article[0]}</div>
						{article[1]}
					</Link>
				))}
			</div>
		</Fragment>
	);
}

export default Issue_4;
