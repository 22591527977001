import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue4/libidinal/1.jpg"
import i2 from "../../../../img/issues/issue4/libidinal/2.jpg"
import i3 from "../../../../img/issues/issue4/libidinal/3.jpg"

function Libidinal_4() {
	return (
		<Fragment>
			<h1 className="articleTitle">The Crypto Libidinal Economy</h1>
			<div id="h"></div>
			<br />
			<div id="h">November 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
            <img src={i1} alt=""/>
            <img src={i2} alt=""/>
            <img src={i3} alt=""/>


            
			</div>
		</Fragment>
	);
}

export default Libidinal_4;
