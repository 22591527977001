import React, { Fragment } from "react";
import chair from "../../../../img/issues/issue3/music/chair.png";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function Music_3() {
	return (
		<Fragment>
			<h1 className="articleTitle">When the Music Stops</h1>
			<div id="h">C.B. Dubois</div>
			<br />
			<div id="h">October 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div>
					<span className="leadLetter" style={{ textTransform: "lowercase" }}>
						H
					</span>
					ow to launch a dumpsterfire shitcoin in under 3 hours:
				</div>
				<div style={{ margin: "0 50px 0 50px" }}>
					1. Use your real identity to assemble a Telegram group of people
					within the DeFi space. Don’t settle for regular old developers, make
					sure you get some folks with a fat twitter following.
				</div>
				<br />
				<div style={{ margin: "0 50px 0 50px" }}>
					2. While still trying to figure out what your coin is trying to do,
					create an airdrop form for the first 50 lucky members of the chat.
					Create and distribute the coin then and there.
				</div>
				<br />
				<div style={{ margin: "0 50px 0 50px" }}>
					3. Now it’s your time to be seen. Open the group to the public and
					create an invite link. Now open a public google hangouts server where
					everyone is discussing exactly what the coin will do.
				</div>
				<br />
				<div style={{ margin: "0 50px 0 50px" }}>
					4. Meanwhile, your chat is being spammed incessantly with shills and
					porn. Don’t moderate the chat or appoint any of the airdropped chosen
					as moderators for at least an hour or so - we want people to start the
					party off on these curated vibes.
				</div>
				<br />
				<div style={{ margin: "0 50px 0 50px" }}>5. ???</div>
				<br />
				<div style={{ margin: "0 50px 0 50px" }}>
					6. Your reputation is ruined, and the SEC has been called. Mission
					accomplished.
				</div>
				<br />
				<br />
				<div>
					You, dear reader, will likely by now recognize the above as the sorry
					story of $FEW, a tale which many have come to understand as an emblem
					of all that is wrong in DeFi. Apologetic twitter ‘influencers’,
					clueless zoomers blinded by the light of quick tokens, and a
					bewildered assortment of developers thrown in as window dressing; this
					is the state of the 24-hour DeFi ‘company’.
				</div>
				<div>
					Flash mob meme magicians and they’re after{" "}
					<span
						style={{
							fontFamily: "inheret",
							fontStyle: "italic",
							textTransform: "lowercase",
						}}
					>
						my{" "}
					</span>
					currency?{" "}
				</div>
				<div>It’s more likely than you think.</div>
				<div>
					When the shitcoin hype cycle happens around an anonymous team, the
					outrage is contained. Look at $MEW: from website to exit scam by the
					lead developer only known as ‘Steve’. Without anyone to go after,
					these big DeFi catastrophes outsource the negative emotions of
					rugpulls onto their victims: because, of course you were being
					irresponsible investing in Strawberry Cheesecake Coin. Our dear token
					contract deployer was simply adding liquidity to better reallocate the
					capital of financial simps. (And with nothing else to lose, it seems
					that $MEW is trying to regain some glory and marketshare with an
					active NFT project. Market signal: Do Not Buy).
				</div>
				<div>
					These points seemed to be totally lost on poor Sam Ratnakar, a
					crypto-powered zoomer who is a (recently made former) co-founder of
					IdeaMarkets.io and creator and distributor of the $FEW token contract.
					The powerful desire to ‘recreate the success of $MEME’ blinded him
					from all of these concerns and, in his mind, greenlit the entire
					process as productive towards the end of DeFi experimentation. Sam
					truly wished to bring about some novel ‘experiment’ which would create
					value out of DeFi mindshare. This is exactly the success story of
					$MEME, which went from laughable idea to engineered product in short
					time. Note that while Jordan Lyall is the public figurehead of $MEME,
					nobody knows who launched the $MEME token. Perhaps a better idea would
					have been to drop a bunch of talented developers a non-tradeable token
					which grants them access to governance over a curated list of
					proposals. But such a thing would take longer than 24 hours to
					influence the space. And you, dear reader, just can’t wait around that
					long for the next pump.
				</div>
				<div>
					The entire debacle is a collective projection of DeFi’s missing
					villains onto poor and naive Sam. It must be assumed that his naivete
					outweighed his malintent because of how he handled the entire
					operation without a thought given to protecting his own ass. If he was
					truly a bad actor, we wouldn’t know who he is. Instead, Sam is our
					lamb of god who we burnt on the pyre of our DeFi sins. The ideal
					scapegoat is always the least suspecting.
				</div>
				<div>
					Meanwhile, ‘influencers’ such as DeFiDude and Anthony Sassano dance
					around the budding flame with cries of ‘dump’ and ‘moon’. But don’t
					forget, reader, it’s all a joke. $MEME is a joke, rugpulls in general
					are a joke, and the punchline is you. So please don’t get angry at
					them for being caught in their own form of revelry and worship.
				</div>
				<div>A lexical lesson for all you wolves on twitter:</div>
				<div>
					{" "}
					<span
						style={{
							fontFamily: "inheret",
							fontStyle: "italic",
							textTransform: "uppercase",
							color: "red",
						}}
					>
						We
					</span>{" "}
					= our community
				</div>
				<div>
					{" "}
					<span
						style={{
							fontFamily: "inheret",
							fontStyle: "italic",
							textTransform: "uppercase",
							color: "red",
						}}
					>
						Need
					</span>{" "}
					= would benefit from
				</div>
				<div>
					<span
						style={{
							fontFamily: "inheret",
							fontStyle: "italic",
							textTransform: "uppercase",
							color: "red",
						}}
					>
						People
					</span>{" "}
					= more collaborators
				</div>
				<div>
					{" "}
					<span
						style={{
							fontFamily: "inheret",
							fontStyle: "italic",
							textTransform: "uppercase",
							color: "red",
						}}
					>
						To
					</span>{" "}
					= with which we might
				</div>
				<div>
					<span
						style={{
							fontFamily: "inheret",
							fontStyle: "italic",
							textTransform: "uppercase",
							color: "red",
						}}
					>
						Dump
					</span>{" "}
					= expand our protocol's mindshare
				</div>
				<div>
					<span
						style={{
							fontFamily: "inheret",
							fontStyle: "italic",
							textTransform: "uppercase",
							color: "red",
						}}
					>
						On
					</span>{" "}
					= and capabilities
				</div>
				<div>
					What can we learn from $FEW? Exactly how not to launch your shitcoin,
					and how not to behave while in shitcoin chats. The spectacular music
					of DeFi has experienced a brief record scratch with $FEW. It is
					unclear how much longer the music will go on - but it would be prudent
					to have a chair ready when it does.
				</div>
				<img
					style={{
						background: "transparent !important",
						webkitBoxShadow: "none",
						mozBoxShadow: "none",
						opacity: ".5",
					}}
					src={chair}
					alt=""
				/>
			</div>
		</Fragment>
	);
}

export default Music_3;
