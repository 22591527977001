import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue3/kulupu/1.jpg";
import i2 from "../../../../img/issues/issue3/kulupu/2.jpg";
import i3 from "../../../../img/issues/issue3/kulupu/3.jpg";

function Kulupu_3() {
	return (
		<Fragment>
			<h1 className="articleTitle">It's Kulupu Time!</h1>
			<div id="h">{null}</div>
			<br />
			<div id="h">October 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<img src={i1} alt="" />
				<div
					style={{ textAlign: "center", color: "#ed66d2", marginTop: "55px" }}
				>
					No Bullshit.
				</div>
				<div style={{ textAlign: "center", color: "#ed66d2" }}>No Premine.</div>
				<div style={{ textAlign: "center", color: "#ed66d2" }}>
					No Rhetoric.
				</div>
				<div style={{ textAlign: "center", color: "#ed66d2" }}>
					Just good vibes, good tech and transcultural polyfuturism.
				</div>
				<div
					style={{
						textAlign: "center",
						color: "#ed66d2",
						marginBottom: "55px",
					}}
				>
					Just say Kulupu.
				</div>
				<img src={i2} alt="" />
				<img src={i3} alt="" />
			</div>
		</Fragment>
	);
}

export default Kulupu_3;
