import React, { Fragment } from "react";
import i1 from "../../../../img/issues/issue1/bondage/1.png";
import i2 from "../../../../img/issues/issue1/bondage/2.webp";

function Bondage_1() {
	return (
		<Fragment>
			<h1 className="articleTitle">Bondage Curve</h1>
			<div id="h">{null}</div>
			<br />
			<div id="h">July 2020</div>
			<div className="articleLine"></div>
			<div className="articleTextContainer">
				<div
					style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
				>
					<img
						style={{
							background: "transparent !important",
							webkitBoxShadow: "none",
							mozBoxShadow: "none",
						}}
						src={i1}
						alt=""
					/>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							textAlign: "center",
							fontStyle: "italic",
						}}
					>
						<div style={{ fontSize: "1.8rem" }}>
							<span className="leadLetter">G</span>et bonded.
						</div>
						<div>
							For supple & sexy shares, deposit ETH to our{" "}
							<a
								className="textLink"
								href="https://etherscan.io/address/0xb14ab2543ea66a7c2313c206ced6d113496928e9#code"
							>
								Bondage Curve contract
							</a>
							.
						</div>
						<div>
							The price of the shares increases proportional to the amount of
							ETH the contract is holding.
						</div>
						<div>
							If you are the rare type of degen who can read contract code and
							ape into it, then{" "}
							<span
								style={{
									font: "inherit",
									background: "#ff52a5",
									fontStyle: "italic",
									textTransform: "none",
								}}
							>
								our panties come off for you.
							</span>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default Bondage_1;
